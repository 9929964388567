import React, { useState, useEffect, useContext, useRef } from "react";
import { auth, db, storage } from "../Firebase-config";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import {
  collection,
  doc,
  getDocs,
  where,
  query,
  addDoc,
  deleteDoc,
  onSnapshot,
  writeBatch,
  updateDoc,
} from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import { Backdrop } from "./Backdrop";
import { motion } from "framer-motion";
import { AccountData } from "../SourceData/AccountData";
import { Popconfirm, Skeleton } from "antd";
import { StripeUI } from "../stripePayments/StripeUI";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { useLoadScript } from "@react-google-maps/api";
import Geohash from "latlon-geohash";
import { PPCreateATeam } from "./PPCreateATeam";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { deleteObject, ref } from "firebase/storage";
import { PPTeam } from "./PPTeam";
import { TeamContext } from "../context/TeamProvider";

export const ProfilePage = ({ handleClose }) => {
  const [profileData, setProfileData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [userData, setUserData] = useState();
  const [saved, setSaved] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);

  const [libraries] = useState(["places"]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD6NvXuF1pWkHVYfaumeolskfIFbMkG6Qc",
    libraries: libraries,
  });

  const addUnSaved = () => {
    setSaved(false);
  };
  const addSaved = () => {
    setSaved(true);
  };

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const { updatePath } = useContext(PathContext);
  const { selectedPath } = useContext(PathContext);
  const { teamsAccessible } = useContext(TeamContext);


  useEffect(() => {
    const fetchUserData = async () => {
      const q = query(
        collection(db, "users"),
        where("userId", "==", currentUser.uid)
      );
      try {
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        const getUserData = data ? data[0] : null;
        setUserData(getUserData);
        setProfileData(getUserData);
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
	setTeamData(teamsAccessible.filter((team) => team.adminUserID === currentUser.uid));
  }, [teamsAccessible]);

  const addInputsToData = (e) => {
    const id = e.target.id;
    const value = e.target.value;
	addUnSaved()

    setProfileData({ ...profileData, [id]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await updateDoc(doc(db, "users", currentUser.uid), { ...profileData });
    addSaved();
  };

  const { fetchTeamsAccessible } = useContext(TeamContext);


  const handleDeleteTeam = async (teamId) => {
    if (teamId === currentUser.uid) {
      openNotificationWithIcon("error", "Cannot Delete myTeam", "", "bottom");
    }
    try {
      // Delete the team document
      const teamDocRef = doc(db, "Teams", teamId);
      await deleteDoc(teamDocRef);

      // Delete the image from storage if it exists and is not the default image
      const defaultTeamPictureUrl =
        "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/EVX%20Team%20Logo%20Placeholder-min.png?alt=media&token=a06f803d-ce72-43fa-98a2-1a6b43d44f33";
      const teamToDelete = teamData.find((team) => team.id === teamId);

      if (teamToDelete.img && teamToDelete.img !== defaultTeamPictureUrl) {
        const storageRef = ref(storage, teamToDelete.img);
        await deleteObject(storageRef);
      }

      // Update the state by filtering out the deleted team
      setTeamData((prevState) => {
        return prevState.filter((team) => team.id !== teamId);
      });
	  
	  fetchTeamsAccessible()

      openNotificationWithIcon(
        "success",
        "Team Deleted",
        "Team + Data Deleted",
        "top"
      );
    } catch (error) {
      openNotificationWithIcon("error", "Error Removing Team", error, "bottom");
    }
  };

  const handleDeleteCoach = (teamId, coachIndex) => {
    setTeamData((prevState) => {
      const teamIndex = prevState.findIndex((team) => team.id === teamId);
      const newCoach = [...prevState[teamIndex].Coach];
      newCoach.splice(coachIndex, 1);
      return [
        ...prevState.slice(0, teamIndex),
        { ...prevState[teamIndex], Coach: newCoach },
        ...prevState.slice(teamIndex + 1),
      ];
    });
  };

  const handleAddCoach = (teamId) => {
	const newCoachName = '';
	const newTeamData = teamData.map((team) => {
	  if (team.id === teamId) {
		const newCoachArray = Array.isArray(team.Coach) ? [...team.Coach, newCoachName] : [newCoachName];
		return {
		  ...team,
		  Coach: newCoachArray,
		};
	  } else {
		return team;
	  }
	});
	setTeamData(newTeamData);
  };  

  const updateTeamData = (teamId, field, value, coachIndex) => {
    // Find the index of the team in the teamData array
    const teamIndex = teamData.findIndex((team) => team.id === teamId);

    // Create a copy of the teamData array to avoid directly modifying the state
    const updatedTeamData = [...teamData];

    // Update the respective field based on the provided field parameter
    if (teamIndex !== -1) {
      if (field === "adminUserId") {
        updatedTeamData[teamIndex].adminUserID = value;
      } else if (field === "adminEmail") {
        updatedTeamData[teamIndex].adminEmail = value;
      } else if (field === "recruitingCoach") {
        updatedTeamData[teamIndex].recruitingCoach = value;
      } else if (field === "recruitingEmail") {
        updatedTeamData[teamIndex].recruitingEmail = value;
      } else if (field === "recruitingPhone") {
        updatedTeamData[teamIndex].recruitingPhone = value;
	} else if (field === "img") {
        updatedTeamData[teamIndex].img = value;
      } else if (field === "coachName") {
        if (
          updatedTeamData[teamIndex].Coach &&
          coachIndex >= 0 &&
          coachIndex < updatedTeamData[teamIndex].Coach.length
        ) {
          updatedTeamData[teamIndex].Coach[coachIndex] = value;
        }
      }
    }

    // Set the updated team data back to the state
    console.log(teamData);
  };

  const confirmChanges = async (teamId, index) => {
    try {
      const selectedTeamData = teamData[index];
      await updateDoc(doc(db, "Teams", teamId), { ...selectedTeamData });
      setShowEditAddress(null);
	  openNotificationWithIcon('success', 'Team Updated', '', 'top')
    } catch (error) {
      console.log(error);
	  openNotificationWithIcon('error', 'Failed To Updated', error, 'bottom')
    }
  };

  const TeamActive = (teamId) => {
    if (teamId.teamId === selectedPath) {
      return (
        <motion.button
          key={teamId}
          className="w-full h-full p-1 font-bold bg-green-500 rounded-md"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handlePathSelection(currentUser.uid)}
        >
          Active
        </motion.button>
      );
    } else {
      return (
        <motion.button
          key={teamId}
          className="w-full h-full p-1 font-bold bg-slate-400 rounded-md"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handlePathSelection(teamId.teamId)} // handlePathSelection pass teamId.teamId
        >
          Activate Team
        </motion.button>
      );
    }
  };

  const AddressSelect = (teamId) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleTeamAddressSelect = async (val) => {
      setValue(val, false);
      clearSuggestions();

      const results = await getGeocode({ address: val });
      const { lat, lng } = await getLatLng(results[0]);

      const latlngObject = { lat, lng };
      const addressName = val;
      const geohash = Geohash.encode(lat, lng, 10);

      // Find the index of the team in the teamData array
      const teamIndex = teamData.findIndex((team) => team.id == teamId.teamId);

      // Create a copy of the teamData array to avoid directly modifying the state
      const updatedTeamData = [...teamData];
      console.log(updatedTeamData);

      // Update the latlng address for the respective team
      updatedTeamData[teamIndex].address = addressName;
      updatedTeamData[teamIndex].latlngAddress = latlngObject;
      updatedTeamData[teamIndex].geohash = geohash;

      // Set the updated team data back to the state
      console.log(updatedTeamData);
    };

    return (
      <Combobox
        id={teamId}
        className="w-64 mx-auto"
        onSelect={handleTeamAddressSelect}
      >
        <ComboboxInput
          className="relative text-black rounded-md w-full p-1"
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Search for an address..."
        />
        <ComboboxPopover>
          <ComboboxList className="absolute z-50 w-96 bg-white mt-1 p-1 rounded-md">
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption
                  key={place_id}
                  value={description}
                  className="bg-slate-200 hover:cursor-pointer m-1 p-1 rounded-xl"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  const [showEditAddress, setShowEditAddress] = useState(null);

  const handleTeamOpen = (teamName) => {
    if (teamName !== teamOpen) {
      setTeamOpen(teamName);
    } else {
      setTeamOpen(null);
    }
  };

  const RenderTeam = () => {
    const MemoizedTeam = React.memo(PPTeam); // Memoize the Team component

    return (
      <div
        className="py-2 h-fit w-[90%] md:w-[97%] shadow-inner bg-slate-100 rounded-sm shadow-slate-800 m-2"
        key="adminTeams"
      >
        {teamData?.map((team, index) => (
          <MemoizedTeam
            key={team.teamName}
            team={team}
            index={index}
            handleTeamOpen={handleTeamOpen}
            teamOpen={teamOpen}
            updateTeamData={updateTeamData}
            handleAddCoach={handleAddCoach}
            handleDeleteCoach={handleDeleteCoach}
            confirmChanges={confirmChanges}
            handleDeleteTeam={handleDeleteTeam}
            showEditAddress={showEditAddress}
            AddressSelect={AddressSelect}
            TeamActive={TeamActive}
            setShowEditAddress={setShowEditAddress}
          />
        ))}
      </div>
    );
  };

  const RenderTeamsAccessible = () => {
    const [hiddenTeams, setHiddenTeams] = useState([]);

    const hideAccessibleTeam = (teamId) => {
      setHiddenTeams(hiddenTeams.filter((id) => id !== teamId));
    };

    const unhideAccessibleTeam = (teamId) => {
      setHiddenTeams([...hiddenTeams, teamId]);
    };

	const nonAdminTeamsAccessible = teamsAccessible.filter((team) => currentUser.uid !== team.adminUserID)

    return (
      <div
        className="flex items-center overflow-x-scroll overflow-y-hidden scrollbar-hide h-[24rem] pt-16 pb-4 shadow-inner bg-slate-100 rounded-b-md shadow-slate-800"
        key={"teamList"}
      >
        {teamsAccessible &&
          nonAdminTeamsAccessible.map((team, index) => {
            if (!hiddenTeams.includes(team.id)) {
              return (
                <div
                  className="flex flex-col m-4 w-fit h-[80%] bg-slate-500 text-white rounded-md shadow-md shadow-slate-800 min-w-[14rem]"
                  key={index}
                >
                  <div className="flex flex-col w-full text-xl text-white text-center justify-center h-full px-4 bg-slate-800 font-bold rounded-sm">
                    {team.teamName}
                  </div>
                  <div className="p-1 mt-1">
                    <TeamActive teamId={team.id} />
                  </div>
                  <motion.button
                    className="p-1 m-1 bg-slate-400 rounded-md shadow-md"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => unhideAccessibleTeam(team.id)}
                  >
                    Expand
                  </motion.button>
                </div>
              ); // Skip rendering of hidden teams
            }

            return (
              <div
                className="flex flex-col h-[80%] m-4 bg-slate-500 text-white text-lg rounded-md shadow-md shadow-slate-800 w-fit"
                key={index}
              >
                <div className="cursor-default flex flex-col w-full min-w-min text-white text-center justify-center min-h-[3rem] px-4 bg-slate-800 font-bold rounded-sm">
                  {team.teamName}
                </div>

                <div className="flex flex-wrap h-52 w-fit min-w-min mx-4">
                  <div className="flex flex-col my-2 w-fit text-black">
                    <label
                      id={team.adminEmail}
                      className="cursor-default rounded-sm shadow-black shadow-sm mb-1 text-center px-2 w-fit bg-[#f1efe9] font-bold"
                    >
                      Owner:
                    </label>
                    <div
                      className="cursor-default ml-1 p-1 pl-2 mr-3 px-2 h-fit shadow-slate-600 shadow-sm rounded-sm bg-slate-200"
                      id={team.id}
                    >
                      {team.adminEmail}
                    </div>
                  </div>
                  <div className="flex flex-col my-2 w-fit text-black">
                    <label
                      className="cursor-default rounded-sm shadow-black shadow-sm mb-1 text-center px-4 w-fit bg-[#f1efe9] font-bold"
                    >
                      Role:
                    </label>
                    <div
                      className="cursor-default ml-2 font-semibold p-1 pl-2 mr-2 h-fit shadow-slate-600 shadow-sm rounded-sm bg-slate-200"
                    >
                      {team.Coach.includes(currentUser.email) ? 'Coach' : 'Player'}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row w-full justify-center align-middle"
                  key={team.buttons}
                >
                  <div className="w-[45%] p-1">
                    <TeamActive teamId={team.id} />
                  </div>

                  <motion.button
                    className="p-1 m-1 w-[45%] max-w-md bg-slate-400 hover:bg-red-400 rounded-md shadow-md"
                    whileHover={{ scale: 1.25 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => hideAccessibleTeam(team.id)}
                  >
                    Hide
                  </motion.button>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const handlePathSelection = (path) => {
    updatePath(path);
  };

  useEffect(() => {
    const handleNavigationClick = (id) => {
      const targetSection = document.getElementById(id);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    const addEventListeners = () => {
      const buttons = document.querySelectorAll(".navigationButton");
      buttons.forEach((button) => {
        button.addEventListener("click", () => {
          const id = button.getAttribute("section-id");
          handleNavigationClick(id);
        });
      });
    };

    const removeEventListeners = () => {
      const buttons = document.querySelectorAll(".navigationButton");
      buttons.forEach((button) => {
        button.removeEventListener("click", () => {
          const id = button.getAttribute("section-id");
          handleNavigationClick(id);
        });
      });
    };

    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []); // Empty dependency array to run the effect only once

  const [proMember, setProMember] = useState();

  useEffect(() => {
    const getProMember = async () => {
      const role = currentUser.reloadUserInfo.customAttributes;
      console.log(role);
      if (role === '{"stripeRole":"Pro"}') {
        setProMember(true);
      } else {
        setProMember(false);
      }
    };
    getProMember();
  }, []);

  const handleDeleteAccount = async () => {
    // Have to create a function to re-ask the user for sign in.********************************
    // const credential = promptForCredentials();

    // await reauthenticateWithCredential(auth.currentUser, credential)

    try {
      const batch = writeBatch(db);

      // Delete documents in "Teams" collection
      const teamSnapshot = await getDocs(
        query(
          collection(db, "Teams"),
          where("adminUserID", "==", currentUser.uid)
        )
      );
      teamSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete document in "users" collection
      const userDocRef = doc(collection(db, "users"), currentUser.uid);
      batch.delete(userDocRef);

      // Commit the batch delete operation
      await batch.commit();

      await deleteUser(auth.currentUser);
      dispatch({ type: "LOGOUT" });
      navigate("/");

      // Success message
      console.log("Documents deleted successfully.");
    } catch (error) {
      console.error("Error deleting documents: ", error);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="absolute overflow-x-hidden scrollbar-hide rounded-md z-50 m-auto items-center top-[5%] w-[95%] sm:w-[85%] h-[93vh] bg-gradient-to-b from-slate-700 via-slate-700 to-slate-950"
        initial={{ y: -500 }}
        animate={{ y: -10 }}
        exit={{ y: -500 }}
      >
        <h1 className="p-3 px-7 w-fit mx-auto font-extrabold text-4xl text-center text-white">
          Profile Page
        </h1>
        <div className="flex">
          <div className="hidden lg:flex flex-col sticky top-0 lg:w-1/5 h-full">
            <p className=" pl-2 ml-1 text-lg text-white font-bold border-b-2 border-white rounded-b-md mt-16 w-[80%]">
              Navigation
            </p>
            <ul className="ml-3">
              <li
                className="mt-5 hover:ml-2 navigationButton"
                section-id="accountDetails"
              >
                <button className="cursor-pointer hover:font-bold">
                  Account Details
                </button>
              </li>
              <li
                className="mt-3 hover:ml-2 navigationButton"
                section-id="accessibleTeams"
              >
                <button className="cursor-pointer hover:font-bold">
                  Accessible Teams
                </button>
              </li>
              <li
                className="mt-3 hover:ml-2 navigationButton"
                section-id="teamAdminPanel"
              >
                <button className="cursor-pointer hover:font-bold">
                  Team Admin Panel
                </button>
              </li>
              <li
                className="mt-3 hover:ml-2 navigationButton"
                section-id="settings"
              >
                <button className="cursor-pointer hover:font-bold">
                  Settings
                </button>
              </li>
              <li className="mt-8 w-fit mx-auto">
                <Menu>
                  <StripeUI memberStatus={proMember} />
                </Menu>
              </li>
            </ul>
          </div>
          <div id="accountDetails" className="flex flex-col w-full lg:w-4/5">
            <div className="sticky z-50 shadow-b-sm shadow-black bg-gradient-to-b from-slate-700 to-slate-900 border-b-2 w-full rounded-b-md mx-auto border-b-white top-0 flex pb-2 p-2 pl-5 pt-8">
              <h2 className="p-3 pb-0 font-bold text-white mt-2 text-2xl">
                Account Details
              </h2>
            </div>
            <form className="lg:w-full" key={"accountForm"}>
              <div className="flex flex-col my-6 flex-wrap text-black h-fit lg:h-96 w-full self-start p-2 mx-auto">
                {AccountData.map((input) => {
                  if (!userData) {
                    return (
                      <div className="rounded-sm h-12 m-1 mt-3" key={input.id}>
                        <label className="mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                          {input.label}:
                        </label>
                        <div className="ml-1 pl-2 h-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full rounded-b-md min-w-fit">
                          <Skeleton.Input
                            className="py-1 h-fit"
                            active
                            size="small"
                          />
                        </div>
                      </div>
                    );
                  } else {
                    const placeholder = userData ? userData[input.id] : null;
                    return (
                      <div className="rounded-sm h-12 m-1 mt-3" key={input.id}>
                        <label className="mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                          {input.label}:
                        </label>
                        {input.type === "select" ? (
                          <select
                            className="cursor-pointer ml-1 pl-1 h-8 text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-[90%] rounded-b-md min-w-fit"
                            id={input.id}
                            onChange={addInputsToData}
                          >
                            <option selected disabled hidden>
                              {placeholder}
                            </option>
                            {input.options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className="ml-1 pl-2 h-8 text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-[90%] rounded-b-md min-w-fit"
                            id={input.id}
                            type={input.type}
                            defaultValue={placeholder}
                            onChange={addInputsToData}
                          />
                        )}
                      </div>
                    );
                  }
                })}
                {userData &&
                  [["userId", userData.userId]].map(([key, value]) => (
                    <div
                      className="rounded-sm text-black h-12 m-1 mt-8 ml-4"
                      key={"myID"}
                    >
                      <label className="shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                        Unique User Id:
                      </label>
                      <div
                        className="ml-1 pt-1 px-2 h-8 text-black shadow-slate-600 bg-slate-200 shadow-sm w-fit select-text"
                        id={"myIDNumber"}
                      >
                        {value}
                      </div>
                    </div>
                  ))}
                <div
                  className="rounded-sm h-12 m-1 mt-3 ml-4"
                  key={"myCurrentTeamIdContainer"}
                >
                  <label className="shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                    Active Team Id:
                  </label>
                  <div
                    className="ml-1 pt-1 px-2 h-8 text-black shadow-slate-600 bg-slate-200 shadow-sm w-fit select-text"
                    id={"myCurrentTeam"}
                  >
                    {selectedPath === "" ? "No Team Selected" : selectedPath}
                  </div>
                </div>
              </div>
              {saved === true ? (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  disabled={true}
                  className="float-right mr-[15%] px-2 mb-4 bg-black rounded-md text-white disabled:opacity-50"
                  onClick={addUnSaved}
                  type="button"
                >
                  SAVED
                </motion.button>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="float-right mr-[15%] px-2 mb-12 hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm  disabled:opacity-50"
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  type="button"
                >
                  SAVE
                </motion.button>
              )}
            </form>
            <div className="sticky z-50 shadow-sm shadow-black bg-gradient-to-b from-slate-700 to-slate-900 border-b-2 w-full rounded-b-md mx-auto border-b-white top-0 flex pb-2 p-2 pl-5 pt-8">
              <h2 className="p-3 pb-0 font-bold text-white mt-2 text-2xl">
                Accessible Teams
              </h2>
            </div>
            <div
              id="accessibleTeams"
              className="bg-slate-200 h-fit w-[95%] mb-24 mx-auto rounded-md shadow-md"
            >
              <RenderTeamsAccessible />
            </div>

            <div className="sticky z-50 shadow-sm shadow-black bg-gradient-to-b from-slate-700 to-slate-900 border-b-2 w-full rounded-b-md mx-auto border-b-white top-0 flex pb-2 p-2 pl-5 pt-8">
              <h3
                id="teamAdminPanel"
                className="p-3 pb-0 font-bold text-white mt-2 text-2xl"
              >
                Team Admin Panel
              </h3>
            </div>
            <PPCreateATeam teamData={teamData} />
            <div className="mb-12">
              <RenderTeam />
            </div>
            <div className="sticky z-50 shadow-sm shadow-black bg-gradient-to-b from-slate-700 to-slate-900 border-b-2 w-full rounded-b-md mx-auto border-b-white top-0 flex pb-2 p-2 pl-5 pt-8">
              <h2 className="p-3 pb-0 font-bold text-white mt-2 text-2xl">
                Settings
              </h2>
            </div>
            <div
              id="settings"
              className="flex flex-col my-6 flex-wrap text-black h-fit lg:h-96 w-full self-start p-2 mx-auto"
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="ml-4 px-2 mb-1 bg-slate-200 hover:text-red-500 rounded-md text-black w-fit h-fit"
                type="submit"
              >
                <Popconfirm
                  className="flex"
                  okType="danger"
                  title="Deleting this account will remove ALL data from your profile and ALL data from any team you are an admin of. Are you sure?"
                  onConfirm={handleDeleteAccount}
                >
                  Delete this Account
                </Popconfirm>
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};
