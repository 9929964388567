import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext"; // Import your AuthContext here
import { db, functions } from "../Firebase-config";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { Menu } from "@headlessui/react";
import { Spin } from "antd";
import { httpsCallable } from "firebase/functions";
import { MdPayment } from "react-icons/md";

export function StripeUI(memberStatus) {
	console.log(memberStatus)
  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const getPubsecret = httpsCallable(functions, "getPubsecret");

  const fetchPubsecret = async () => {
    try {
      const response = await getPubsecret();
      const pubsecret = response.data.pubsecret;
      return pubsecret;
      // Use the pubsecret in your React.js code as needed
    } catch (error) {
      console.error("Error retrieving pubsecret:", error);
    }
  };

  // Event listener for subscribe form submission
  const handleSubscribe = async (event) => {
    event.preventDefault();
    document.querySelectorAll("button").forEach((b) => (b.disabled = true));
    setLoading(true); // set loading to true befre data has been retrieved

    const createCheckoutSession = async (
      customerId,
      price,
      successUrl,
      cancelUrl
    ) => {
      const session = await addDoc(
        collection(db, "users", customerId, "checkout_sessions"),
        {
          price: "price_1P41feGqTnaqylD9ZDPhAdik",
          allow_promotion_codes: true,
          success_url: successUrl,
          cancel_url: cancelUrl,
        }
      );

      // Store the sessionId in the Firestore document
      await updateDoc(
        doc(db, "users", customerId, "checkout_sessions", session.id),
        {
          sessionId: session.id,
        }
      );

      return session;
    };

    const session = await createCheckoutSession(
      currentUser.uid,
      doc.price,
      window.location.origin,
      window.location.origin
    );
    const sessionRef = doc(
      db,
      "users",
      currentUser.uid,
      "checkout_sessions",
      session.id
    );
    const unsubscribe = onSnapshot(sessionRef, async (snapshot) => {
      if (snapshot.data()) {
        const { error, sessionId } = snapshot.data();
        if (sessionId) {
          const stripeKey = await fetchPubsecret();
          // We have a session, let's redirect to Checkout
          const stripe = window.Stripe(stripeKey);
          await stripe.redirectToCheckout({ sessionId: sessionId });
          setLoading(false);
          unsubscribe(); // Unsubscribe from further updates
        } else if (error) {
          alert("Session Failed");
          console.log(error);
        }
      }
    });
  };

  const openCustomerPortal = async () => {
    setLoading(true);
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.href = data.url;

    setLoading(true);
  };

  return (
    <>
      {memberStatus.memberStatus === true ? (
        <Menu.Button
          onClick={openCustomerPortal}
          className="flex font-bold w-fit ml-auto my-4"
        >
          Manage Subscription <MdPayment className="mt-1 mx-1 scale-110" />
          <span>
            {loading ? (
              <div className="flex justify-center items-center">
                <Spin size="medium" />
              </div>
            ) : null}
          </span>
        </Menu.Button>
      ) : (
        <Menu.Button
          onClick={(e) => handleSubscribe(e)}
          className="font-bold float-right my-4"
        >
          {/* Add your form fields here */}
          <p>
            Upgrade to{" "}
            <span className="text-bold text-white bg-green-500 hover:bg-yellow-400 px-1 rounded-lg">
              Pro
            </span>
            {loading ? (
              <div className="flex justify-center items-center">
                <Spin size="medium" />
              </div>
            ) : null}{" "}
          </p>
        </Menu.Button>
      )}
    </>
  );
}
