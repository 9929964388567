import React, { useState, useEffect, useContext }from 'react'
import { AuthContext } from '../context/AuthContext'
import { PathContext } from '../context/PathContext'
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs'
import { motion } from 'framer-motion'


export const HActiveTeam = (teamId) => {

    const { currentUser } = useContext(AuthContext)
    const { selectedPath } = useContext(PathContext)
    const { updatePath } = useContext(PathContext)

    const [selected, setSelected] = useState()


    useEffect(() => {
        setSelected(selectedPath)
    },[selectedPath])

    const TeamActive = () => {    
        if (teamId.teamId === selected ) {
            return (
                <motion.button key={teamId.teamId}
                className='p-1 m-1 font-bold bg-green-500 rounded-md'
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
                onClick={() => handlePathSelection(currentUser.uid)}
                >
                    <BsCheckCircleFill />
                </motion.button>
            )
        } else {
            return (
                <motion.button key={teamId.teamId}
                className='p-1 m-1 font-bold bg-slate-400 rounded-md'
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
                onClick={() => handlePathSelection(teamId.teamId)} // handlePathSelection pass team.teamID
                >
                    <BsCheckCircle />
                </motion.button>
            )}
      }

    const handlePathSelection = (path) => {
        updatePath(path)
    };
    
  return (
    <TeamActive />
  )
}