import { useContext, useEffect, useRef } from 'react';
import { HeaderContext } from '../context/HeaderContext';

export const HeaderScrollVisibility = () => {
  const { setIsHeaderVisible } = useContext(HeaderContext);
  const scrollableDivRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollableDivRef.current.scrollTop;
      setIsHeaderVisible(scrollTop <= 0);
    };

    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setIsHeaderVisible]);

  return scrollableDivRef;
};
