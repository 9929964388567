import { Button, Modal } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { db, storage } from "../Firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { PathContext } from "../context/PathContext";
import { AuthContext } from "../context/AuthContext";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";
import { TeamContext } from "../context/TeamProvider";

export function PPCreateATeam({ teamData }) {
  const { selectedPath } = useContext(PathContext);
  const { currentUser } = useContext(AuthContext);
  const { fetchTeamsAccessible } = useContext(TeamContext);


  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState(null);
  const [ImgUrl, setImgUrl] = useState();

  const addClose = () => setShowCreateTeam(false);
  const addOpen = () => setShowCreateTeam(true);

  const defaultTeamPictureUrl =
      "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/EVX%20Team%20Logo%20Placeholder-min.png?alt=media&token=a06f803d-ce72-43fa-98a2-1a6b43d44f33";

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(storage, `${currentUser.uid}/${file.name}`);
      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL
            setImgUrl(downloadURL);
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess(response) {
      console.log("File uploaded successfully:", response);
      // Update your data state with the downloadURL from response
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  const createTeam = async (e) => {
    e.preventDefault();
    if (newTeamName != null) {
      await addDoc(collection(db, "Teams"), {
        adminUserID: currentUser.uid,
        teamName: newTeamName,
        img: ImgUrl ? ImgUrl : defaultTeamPictureUrl,
        adminEmail: currentUser.email,
        Coach: [],
      });
      //   fetchAdminsTeamsRef.current(); // call the fetchTeams function from the ref
	  fetchTeamsAccessible()
      openNotificationWithIcon(
        "success",
        "Team Created",
        "Scroll down to find your newly created team",
        "top"
      );
      addClose();
    } else {
      alert("Input a Team Name");
      openNotificationWithIcon(
        "error",
        "Input a Team Name",
        "A new team must have a name",
        "bottom"
      );
    }
  };

  const handleOpenCreateTeam = () => {
    if (teamData?.length <= 10) {
      addOpen();
    } else {
      openNotificationWithIcon(
        "warning",
        "Team Limit Hit",
        "You are currently the owner of 10 or more teams",
        "top"
      );
    }
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="w-fit h-12 ml-4 mr-auto px-2 my-2 font-bold bg-slate-200 rounded-md text-black hover:text-yellow-500 disabled:opacity-50"
        onClick={handleOpenCreateTeam}
      >
        Create a New Team
      </motion.button>
      <Modal
        className="h-screen w-fit pt-[5%]"
        centered
        width={900}
        open={showCreateTeam}
        onOk={() => addClose()}
        onCancel={() => addClose()}
        footer={[
          <Button key="back" onClick={() => addClose()}>
            Cancel
          </Button>,
          <motion.button
            key="submit"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="ml-4 p-3 mb-1 w-fit mt-4 font-semibold bg-slate-800 rounded-md text-white disabled:opacity-50"
            onClick={(e) => createTeam(e)}
            type="submit"
          >
            Create New Team
          </motion.button>,
        ]}
      >
        <div className="flex flex-col bg-slate-200 h-[50vh] w-full p-2 mt-6 rounded-md shadow-md">
          <div className="flex w-full mt-6 h-[90%]">
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col justify-center align-middle w-full h-2/3">
                <div className="w-full h-full border-2 rounded-xl border-black">
                  {/* {croppedFile && (
                    <img
                      className="rounded-xl mx-auto max-h-full max-w-[80%] h-fit w-fit"
                      src={
                        croppedFile ? URL.createObjectURL(croppedFile) : null
                      }
                      alt="No Upload"
                    />
                  )}
                  {file && !croppedFile && (
                    <ImageCropper
                      file={URL.createObjectURL(file)}
                      setCroppedFile={setCroppedFile}
                      aspect={1}
                    />
                  )} */}
                  <div className="relative h-full w-full flex flex-row flex-nowrap">
                    {!ImgUrl ? (
                      <div className="h-full w-full bg-white rounded-lg shadow-sm shadow-slate-900">
                        <ImgCrop aspect={1} rotationSlider>
                          <Dragger {...uploadProps}>Add Team Logo</Dragger>
                        </ImgCrop>
                      </div>
                    ) : (
                      <div className="flex w-fit mx-auto">
                        <img
                          className="ml-2 mx-auto h-full bg-slate-100 rounded-xl"
                          src={ImgUrl}
                          alt="profile"
                        />
                        <Button
                          className="ml-2 mt-auto w-fit h-fit mx-auto font-semibold rounded-md"
                          onClick={() => {
                            setImgUrl(null);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="h-fit w-fit mx-auto flex flex-col mb-8 sm:mb-0">
                <label>Upload Picture </label>
                <input
                  className=""
                  type="file"
                  id="file"
                  onChange={(e) => handleFileUpload(e)}
                />
              </div> */}
            </div>
            <div className="flex flex-col w-1/2 pl-3 justify-center">
              <label className="w-fit z-10 shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                Team Name:
              </label>
              <input
                className="pl-2 ml-2 h-8 shadow-slate-600 shadow-sm w-2/3 rounded-sm text-black"
                type="string"
                placeholder={"New Team Name..."}
                onChange={(e) => setNewTeamName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
