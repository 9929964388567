import { AnimatedRoutes } from './Pages/AnimatedRoutes';

const App = () => {

  return (

    <div className="App bg-gradient-to-b from-slate-700 to-slate-950 h-screen w-screen overflow-hidden">
      <AnimatedRoutes />
    </div>
  );
}

export default App;