import { Input, Skeleton } from "antd";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";

export function HLatestScoresWidget({ setShowTeamFeed }) {
  const [loading, setLoading] = useState(true);

  const CFBscores =
    "https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard";
  const NFLscores =
    "https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard";

  const [scoresUrl, setScoresUrl] = useState(CFBscores);

  const leagueSwitch = () => {
    if (scoresUrl === CFBscores) {
      setLoading(true);
      setScoresUrl(NFLscores);
    } else {
      setLoading(true);
      setScoresUrl(CFBscores);
    }
  };

  const fetchScores = async () => {
    // Fetch the data
    try {
      const response = await fetch(scoresUrl);
      const data = await response.json();

      // Extract the scores
      const scores = data.events.map((event) => {
        const top25RankT1 =
          event.competitions[0].competitors[0].team.conferenceId &&
          event.competitions[0].competitors[0].curatedRank?.current <= 25
            ? event.competitions[0].competitors[0].curatedRank?.current
            : null;
        const top25RankT2 =
          event.competitions[0].competitors[1].team.conferenceId &&
          event.competitions[0].competitors[1].curatedRank?.current <= 25
            ? event.competitions[0].competitors[1].curatedRank?.current
            : null;

        return {
          team1: [
            event.competitions[0].competitors[0].team.abbreviation,
            event.competitions[0].competitors[0].team.logo,
            top25RankT1,
            event.competitions[0].competitors[0].records[0].summary,
          ],
          team2: [
            event.competitions[0].competitors[1].team.abbreviation,
            event.competitions[0].competitors[1].team.logo,
            top25RankT2,
            event.competitions[0].competitors[1].records[0].summary,
          ],
          scores: [
            event.competitions[0].competitors[1].score,
            event.competitions[0].competitors[0].score,
          ],
          status: event.status.type.shortDetail,
        };
      });

      console.log("Scores Data:", data);

      console.log("Scores:", scores);
      setLoading(false);
      setNoScores(``);

      return scores;
    } catch (err) {
      setNoScores(`No Scores available. Check back another time.`);
      setShowTeamFeed(true);
      setLoading(false);

      if (scoresUrl === CFBscores) {
        setScoresUrl(NFLscores);
      }
    }
  };

  const [scores, setScores] = useState([]);
  const [noScores, setNoScores] = useState([]);

  const [fetchedScoreboard, setFetchedScoreBoard] = useState([]);

  useEffect(() => {
    async function getScores() {
      const fetchedScores = await fetchScores();
      setFetchedScoreBoard(fetchedScores);
      setScores(fetchedScores);
    }

    getScores();
  }, [scoresUrl]);

  function handleSearchRoster(e) {
    const searchValue = e.target.value;

    if (searchValue.length <= 0) {
      setScores(fetchedScoreboard);
    } else {
      const scoresCopy = [...fetchedScoreboard];
      const filteredScores = scoresCopy.filter(
        (player) =>
          player.team1[0].toLowerCase().includes(searchValue.toLowerCase()) ||
          player.team2[0].toLowerCase().includes(searchValue.toLowerCase())
      );

      setScores(filteredScores);
    }
  }

  return (
    <div
      className="overflow-hidden z-0 -mt-2 mb-4 md:mb-0 relative scoreboard h-full max-h-[12rem] min-h-[11rem] sm:-mt-[1px] text-lg overflow-none w-full mx-auto rounded-lg"
    >
      <div className="block relative mx-auto min-h-10 overflow-x-auto snap-x scrollbar-thin scrollbar-track-transparent scrollbar-track-rounded-sm scrollbar-thumb-white">
        <div className="flex h-9 w-2/3 z-0 flex-row shadow-black shadow-sm sticky top-0 left-0 font-semibold bg-slate-800 sm:w-[70%] md:w-1/2 mx-auto md:ml-0 p-1 py-2 rounded-b-lg">
          <div className="ml-auto text-sm text-white">Search:</div>
          <Input
            size="small"
            onChange={(e) => handleSearchRoster(e)}
            className="lg:w-[65%] ml-2 mr-auto"
          ></Input>
          <button
            onClick={leagueSwitch}
            className="z-20 hover:text-green-400 font-bold rounded-md text-white px-2 md:-my-1 ml-2 bg-slate-700"
          >
            {scoresUrl === CFBscores ? "CFB" : "NFL"}
          </button>
        </div>
        {!loading ? (
          <>
            <div className="scores font-Kanit mt-[-0.75rem] flex mx-auto">
              {scores &&
                scores.map((score, index) => (
                  <div
                    key={index}
                    className="score flex flex-col w-[13rem] min-w-max snap-center mx-1 my-1 rounded-lg shadow-sm shadow-slate-700 p-4 whitespace-nowrap border-slate-50 bg-slate-100 md:min-w-[13rem]"
                  >
                    <div className="mb-auto w-[10rem]">{score.status}</div>
                    <div className="flex">
                      <div className="flex flex-col h-full w-full mx-auto">
                        <div className="flex my-auto relative">
                          <img
                            src={score.team2[1]}
                            alt="Team Logo"
                            className="w-7 mr-2 aspect-auto"
                          />
                          <span className="absolute font-semibold top-[-1rem] left-[-.4rem] text-black text-sm">
                            {score.team2[2]}
                          </span>
                          <span className="flex relative font-bold my-auto">
                            {score.team2[0]}
                            <span className=" absolute top-5 left-1 text-slate-400 text-sm scale-[90%] ml-1 my-auto">
                              {`(${score.team2[3]})`}
                            </span>
                          </span>
                        </div>
                        <span className="my-1"></span>
                        <div className="flex relative">
                          <img
                            src={score.team1[1]}
                            alt="Team Logo"
                            className="w-7 mr-2 aspect-auto"
                          />
                          <span className="absolute font-semibold top-[-1rem] left-[-.4rem] text-black text-sm">
                            {score.team1[2]}
                          </span>
                          <span className="flex relative font-bold my-auto">
                            {score.team1[0]}
                            <span className=" absolute top-5 left-1 text-slate-400 text-sm scale-[90%] ml-1 my-auto">
                              {`(${score.team1[3]})`}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col w-fit ml-auto mr-2 mb-[-8px]">
                        <span className="text-2xl pt-2">{score.scores[0]}</span>
                        <span className="">-</span>
                        <span className="text-2xl">{score.scores[1]}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <motion.div
		  initial={{ opacity: 0, }}
		animate={{ opacity: 1}}
		exit={{ opacity: 0 }} 
		  
		  className="flex flex-nowrap opacity-80 h-[9rem] text-lg overflow-x-auto overflow-y-hidden scrollbar-hide w-full mx-auto mb-2 rounded-lg">
            <div className="mt-[-0.75rem] flex flex-col w-[14rem] snap-center mx-1 my-1 rounded-lg shadow-sm shadow-slate-700 p-4 whitespace-nowrap border-slate-50 bg-slate-100 md:min-w-[13rem]">
              <Skeleton active />
            </div>
            <div className="mt-[-0.75rem] flex flex-col w-[14rem] snap-center mx-1 my-1 rounded-lg shadow-sm shadow-slate-700 p-4 whitespace-nowrap border-slate-50 bg-slate-100 md:min-w-[13rem]">
              <Skeleton active />
            </div>
            <div className="mt-[-0.75rem] flex flex-col w-[14rem] snap-center mx-1 my-1 rounded-lg shadow-sm shadow-slate-700 p-4 whitespace-nowrap border-slate-50 bg-slate-100 md:min-w-[13rem]">
              <Skeleton active />
            </div>
          </motion.div>
        )}
      </div>
      <div className="text-white w-fit mx-auto h-full">{noScores}</div>
    </div>
  );
}
