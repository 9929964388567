import React, { useContext, useEffect, useState } from "react";
import { TeamContext } from "../context/TeamProvider";
import { Card, Input } from "antd";
import { FaPhone, FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export function HRecruitingProfileCards() {
  const { Meta } = Card;

  const { teamRosterData } = useContext(TeamContext);

  const [pubRecruitingProfiles, setPubRecruitingProfiles] = useState([]);
  const [fetchedProfiles, setFetchedProfiles] = useState([]);

  useEffect(() => {
    const fullpubRecruitingProfiles = teamRosterData.filter(
      (player) => player.publicRecruit === "Yes"
    );

    setFetchedProfiles(fullpubRecruitingProfiles);
    setPubRecruitingProfiles(fullpubRecruitingProfiles);
  }, [teamRosterData]);

  function handleSearchPubProfiles(e) {
    const searchValue = e.target.value;

    if (searchValue.length <= 0) {
      setPubRecruitingProfiles([...fetchedProfiles]);
    } else {
      const profilesCopy = [...fetchedProfiles];
      const pubRecruitingProfiles = profilesCopy.filter(
        (player) =>
          player["full-name"]
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          player["full-name"].toLowerCase().includes(searchValue.toLowerCase())
      );

      setPubRecruitingProfiles(pubRecruitingProfiles);
    }
  }

  const levelAbbreviations = {
    "Division I FBS": "FBS",
    "Division I FCS": "FCS",
    "Division II": "D2",
    "Division III": "D3",
    NAIA: "NAIA",
    NJCAA: "JUCO",
    "Sprint Football": "Sprint",
  };

  const levelOrder = [
    "Division I FBS",
    "Division I FCS",
    "Division II",
    "Division III",
    "NAIA",
    "NJCAA",
    "Sprint Football",
  ];

  return (
    <div className="flex group z-20 flex-wrap p-1 pt-10 max-h-[41rem] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-rounded-md scrollbar-thumb-slate-100 pb-80">
      <div className="flex h-9 w-2/3 z-10 flex-row shadow-black shadow-sm absolute top-6 left-0 font-semibold bg-slate-800 sm:w-[70%] md:w-1/2 mx-auto md:ml-0 p-1 py-2 rounded-b-lg">
        <div className="ml-auto text-sm text-white">Search:</div>
        <Input
          size="small"
          onChange={(e) => handleSearchPubProfiles(e)}
          className="lg:w-[65%] ml-2 mr-auto"
        ></Input>
      </div>
      {pubRecruitingProfiles.map((player, index) => {
        const full_name = player["full-name"]; // Replace with your actual full name
        const nameParts = full_name.split(" "); // Split the full name into parts by space
        const firstName = nameParts[0]; // Get the first name
        const lastName = nameParts.slice(1).join(" "); // Get the last name by joining the remaining parts

        const abbreviations = levelOrder
          .filter((value) => player.expectedLOC?.includes(value))
          .map((value) => levelAbbreviations[value])
          .join(" / ");

        return (
          <div key={index} className="group group/item">
            <Card
              key={player.id}
              id={player.id}
              className="w-52 h-[22rem] [perspective:1000px] [transform-style:preserve-3d] group-hover/item:[transform:rotateY(180deg)] transition-all duration-300 mb-2 p-1 shadow-sm shadow-slate-600 m-1"
              cover={
                <div className="-mb-6 group-hover/item:opacity-0 transition-all">
                  <img
                    style={{
                      WebkitMaskImage:
                        "linear-gradient(to top, transparent, white)",
                      maskImage: "linear-gradient(to top, transparent, white)",
                    }}
                    className="w-52 h-48 hover:opacity-100 opacity-80 transition-all"
                    alt="example"
                    src={player.img}
                  />
                </div>
              }
              actions={[]}
            >
              <Meta
                title={
                  <div className="flex w-full group-hover/item:opacity-0 transition-all">
                    <div className="absolute top-0 left-0 p-1 px-2 backdrop-blur-md rounded-md text-base font-bold border-2 border-white w-fit my-auto">
                      {player.year}
                    </div>
                    <div className="flex flex-col w-fit mx-auto">
                      <div className="relative my-auto text-lg">
                        {firstName}
                      </div>
                      <div className="text-2xl w-fit font-bold ml-1 -mt-1">
                        {lastName}
                      </div>
                    </div>
                  </div>
                }
                description={
                  <div className="flex flex-col group-hover/item:opacity-0 transition-all">
                    <div className="flex w-fit mx-auto">
                      <div className="mr-2 ">#{player["jersey-number"]}</div>
                      {player.position}
                      {player.altPosition ? " / " + player.altPosition : null}
                    </div>
                    <div className="flex justify-center">
                      <div>{player.height}</div>
                      <div className="ml-2">{player.weight}</div>
                    </div>
                    <div className="text-base mt-1 text-green-400 font-bold">{abbreviations}</div>
                  </div>
                }
              />
              <div className="absolute inset-0 h-full w-full group-hover/item:[transform:rotateY(180deg)] group-hover/item:opacity-100 transition-all opacity-0 [transform:rotateY(180deg)]">
                <div className="flex flex-col w-full mx-auto">
                  <div className="flex align-middle justify-center bg-slate-800 pb-1 text-white relative w-full text-lg rounded-lg rounded-t-sm">
                    <div className="text-xl h-fit w-fit font-semibold">
                      {firstName}
                    </div>
                    <div className="ml-1 text-2xl w-fit font-bold">
                      {lastName}
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <div className="flex ml-1">
                      <span className="font-semibold mr-1 text-slate-500">HS:</span>
                      <div>{player.highSchool}</div>
                    </div>
                    <div className="flex ml-1">
                      <span className="font-semibold mr-1 text-slate-500">YR:</span>
                      <div>{player.hsgradYear}</div>
                      <div className="ml-[2px]">{`(${player.year})`}</div>
                    </div>
                    <div className="flex ml-1">
                      <span className="font-semibold mr-1 text-slate-500">POS:</span>
                      <div>{player.position}</div> 
                      <div>{player.altPosition ? " / " + player.altPosition : null}</div>
                    </div>
                    <div className="flex ml-2">
                      <span className="flex mr-1">
                        <FaXTwitter className="mt-1 mr-[1px]" />:
                      </span>
                      <a
                        href={`https://twitter.com/${player.Xtwitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs"
                      >
                        {player.Xtwitter}
                      </a>
                    </div>
                    <div className="flex ml-2">
                      <span className="flex mr-1">
                        <FaPhone className="mt-1 mr-[1px]" />:
                      </span>
                      <div className="text-xs">{player.phone}</div>
                    </div>
                    <div className="flex ml-2 w-full scrollbar-hide overflow-x-auto">
                      <span className="flex mr-1">
                        <MdEmail className="mt-1 mr-[1px]" />:
                      </span>
                      <div className="text-xs">{player.email}</div>
                    </div>
                    <div className="flex mt-1 w-full divide-x-2 text-xs text-slate-500">
                      <div className="flex justify-center w-1/3">
                        Leadership
                      </div>
                      <div className="flex justify-center w-1/3">
                        Loves Ball
                      </div>
                      <div className="flex justify-center w-1/3">
                        Work Ethic
                      </div>
                    </div>
                    <div className="flex w-full divide-x-2">
                      <div className="flex justify-center w-1/3">
                        {player.leadership}
                      </div>
                      <div className="flex justify-center w-1/3">
                        {player.loveFootball === "10 (A Football Life)" ? "10" : player.loveFootball}
                      </div>
                      <div className="flex justify-center w-1/3">
                        {player.workEthic  === "10 (First in, Last Out)" ? "10" : player.workEthic}
                      </div>
                    </div>
                    <div className="flex justify-center align-middle bg-slate-800 bg-opacity-90 pb-1 w-full text-xs px-auto font-semibold text-green-400 -mb-1 rounded-b-md">
                      {abbreviations}
                    </div>
                    {player.filmLink ? (
                      <div className="w-[95%] mx-auto max-w-[36rem] mt-1 rounded-md aspect-video relative">
                        <div
                          className="absolute top-0 left-0 w-full h-full"
                          dangerouslySetInnerHTML={{ __html: player.filmLink }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
