import React, { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";

export const PathContext = createContext({
  selectedPath: "",
  updatePath: () => {},
  selectedLocation: "",
  updateLocation: () => {},
});

export const PathProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const currentUserId = currentUser ? currentUser.uid : "";

  // Load the selected path from localStorage on component mount
  const [selectedPathState, setSelectedPathState] = useState(() => {
    const storedPath = localStorage.getItem("selectedPath");
    return storedPath || currentUserId; // If there's no stored path, default to currentUserId
  });

  const [selectedLocationState, setSelectedLocationState] = useState(() => {
    const storedLocation = localStorage.getItem("selectedLocation");
    return storedLocation || { lat: 20, lag: 20 }; // Default to an empty string if there's no stored location
  });

  useEffect(() => {
    // Save the selected path to localStorage whenever it changes
    localStorage.setItem("selectedPath", selectedPathState);
  }, [selectedPathState]);

  useEffect(() => {
    // Save the selected location to localStorage whenever it changes
    localStorage.setItem("selectedLocation", selectedLocationState);
  }, [selectedLocationState]);

  const updatePath = (path) => {
    setSelectedPathState(path);
  };

  const updateLocation = (location) => {
    setSelectedLocationState(location);
  };

  return (
    <PathContext.Provider
      value={{
        selectedPath: selectedPathState,
        selectedLocation: selectedLocationState,
        updatePath,
        updateLocation,
      }}
    >
      {children}
    </PathContext.Provider>
  );
};
