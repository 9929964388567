import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AuthContext } from '../context/AuthContext';
import { PathProvider } from '../context/PathContext';
import { TeamProvider } from '../context/TeamProvider';
import { Header } from '../NavBars/Header';
import { LeftNav } from '../NavBars/LeftNav';
import { Spin } from 'antd';

// Directly imported critical components
import Home from './Home';
import HomeMobile from './HomeMobile';

// Lazy-loaded components
const Login = lazy(() => import('./Login'));
// const Home = lazy(() => import('./Home'));
// const HomeMobile = lazy(() => import('./HomeMobile'));
const GradeSheet = lazy(() => import('./GradeSheet'));
const RosterEditor = lazy(() => import('./RosterEditor'));
const SeasonSelect = lazy(() => import('./SeasonSelect'));
const VisualData = lazy(() => import('./VisualData'));
const DepthCharts = lazy(() => import('./DepthCharts'));
const Recruit = lazy(() => import('./Recruit'));
const PrivacyPolicy = lazy(() => import('../Terms/PPolicy/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../Terms/PPolicy/TermsOfUse'));
const WristCaller = lazy(() => import('./WristCaller'));

export function AnimatedRoutes() {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const { pathname } = location;

  const [landingPage, setLandingPage] = useState(false);
  const [proMember, setProMember] = useState();
  const isMobile = window.innerWidth < 1036;

  useEffect(() => {
    if (pathname === '/' || pathname === '/privacy' || pathname === '/terms') {
      setLandingPage(true);
    } else {
      setLandingPage(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (!currentUser) return;
    const getProMember = async () => {
      const role = currentUser?.reloadUserInfo?.customAttributes;
      setProMember(role === '{"stripeRole":"Pro"}');
    };
    getProMember();
  }, [currentUser]);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" state={{ from: location }} replace />;
  };

  return (
    <PathProvider>
      <TeamProvider>
        {!landingPage && currentUser && <Header proMember={proMember} />}
        {!landingPage && currentUser && <LeftNav />}
        <AnimatePresence mode="wait" initial={false}>
          <Suspense fallback={<div className='h-full w-full relative'><Spin size="large" className='absolute top-1/2 left-1/2' /></div>}>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Login />} />
              {isMobile ? (
                <Route path="/Home" element={<RequireAuth><HomeMobile proMember={proMember} /></RequireAuth>} />
              ) : (
                <Route path="/Home" element={<RequireAuth><Home proMember={proMember} /></RequireAuth>} />
              )}
              <Route path="/RosterEditor" element={<RequireAuth><RosterEditor isMobile={isMobile} /></RequireAuth>} />
              <Route path="/DepthCharts" element={<RequireAuth><DepthCharts /></RequireAuth>} />
              <Route path="/SeasonSelect" element={<RequireAuth><SeasonSelect /></RequireAuth>} />
              <Route path="/VisualData" element={<RequireAuth><VisualData /></RequireAuth>} />
              {proMember === true && (
                <>
                  <Route path="/GradeSheet" element={<RequireAuth><GradeSheet isMobile={isMobile} /></RequireAuth>} />
                  <Route path="/Recruit" element={<RequireAuth><Recruit /></RequireAuth>} />
                  <Route path="/WristCaller" element={<RequireAuth><WristCaller /></RequireAuth>} />
                </>
              )}
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </TeamProvider>
    </PathProvider>
  );
}
