import React, { useContext } from "react";
import { VscJersey } from "react-icons/vsc";
import { LiaBusAltSolid } from "react-icons/lia";
import { GiBinoculars } from "react-icons/gi";
import { Statistic } from "antd";
import CountUp from "react-countup";
import { TeamContext } from "../context/TeamProvider";

export function HTeamStats() {
  const formatter = (value) => <CountUp end={value} separator="," />;

  const { teamRosterData } = useContext(TeamContext);

  const fullTeamCount = teamRosterData.length;
  const travelTeamCount = teamRosterData.filter(
    (player) => player.scoutTeam !== "Y"
  ).length;
  const scoutTeamCount = teamRosterData.filter(
    (player) => player.scoutTeam === "Y"
  ).length;
  const frTeamCount = teamRosterData.filter(
    (player) => player.year === "Fr"
  ).length;
  const soTeamCount = teamRosterData.filter(
    (player) => player.year === "So"
  ).length;
  const jrTeamCount = teamRosterData.filter(
    (player) => player.year === "Jr"
  ).length;
  const srTeamCount = teamRosterData.filter(
    (player) => player.year === "Sr"
  ).length;
  const pubRecruitingProfiles = teamRosterData.filter(
    (player) => player.publicRecruit === "Yes"
  ).length;

  return (
    <div className="w-full h-fit">
      <div className="flex flex-col h-full w-full m-auto pb-3">
        <div className="flex mt-4 mb-auto mx-auto h-fit w-full">
          <Statistic
            className="w-fit mx-auto font-semibold"
            title="Roster Total"
            prefix={<VscJersey />}
            value={fullTeamCount}
            formatter={formatter}
          />
          <div className="flex scale-90 mx-auto">
            <div className="ml-4 h-fit w-fit">
              <Statistic
                title="Freshmen"
                value={frTeamCount}
                formatter={formatter}
              />
            </div>
            <div className="ml-4 h-fit w-fit">
              <Statistic
                title="Sophomores"
                value={soTeamCount}
                formatter={formatter}
              />
            </div>
            <div className="ml-4 h-fit w-fit">
              <Statistic
                title="Juniors"
                value={jrTeamCount}
                formatter={formatter}
              />
            </div>
            <div className="ml-4 h-fit w-fit">
              <Statistic
                title="Seniors"
                value={srTeamCount}
                formatter={formatter}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-2 mb-auto ml-10 h-fit w-fit">
          <div className="mr-4 h-fit w-fit">
            <Statistic
              title="Travel Total"
              prefix={<LiaBusAltSolid className="mr-1 mt-1" />}
              value={travelTeamCount}
              formatter={formatter}
            />
          </div>
          <div className="ml-4 h-fit w-fit">
            <Statistic
              title="Scout Total"
              prefix={<GiBinoculars className="mr-1 mt-1" />}
              value={scoutTeamCount}
              formatter={formatter}
            />
          </div>
          <div className="ml-4 h-fit w-fit">
            <Statistic
              className="w-fit ml-12"
              title="Recruiting Profiles"
              value={pubRecruitingProfiles}
              formatter={formatter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
