import React, { useState, useReducer, createContext, useEffect } from "react";
import { auth } from "../Firebase-config";
import { AuthReducer } from "./AuthReducer";
import EVXLoading from "../img/EVXLoading.mp4";

import { AnimatePresence, motion } from "framer-motion";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch({ type: "SET_CURRENT_USER", payload: user });
      localStorage.setItem("user", JSON.stringify(user));
    //   setLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  const handleAnimationEnd = () => {
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      <AnimatePresence>
        {loading && (
          <motion.div
            key="LogoScreen"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            className="absolute z-50 top-0 left-0 h-screen w-screen overflow-hidden flex bg-[#182333]"
          >
            <video
              onEnded={handleAnimationEnd}
              className="h-fit w-[101%] m-auto rounded-lg border-b border-[#182333]"
              autoPlay
              muted
              disableRemotePlayback
              playsInline
            >
              <source src={EVXLoading} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        key="Content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={!loading ? "block" : "hidden"}
      >
        {children}
      </motion.div>
    </AuthContext.Provider>
  );
};
