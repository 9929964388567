import React, { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { HTeamFeed } from "../components/HTeamFeed";
import { HLatestScoresWidget } from "../components/HLatestScoresWidget";
import { HLatestStandingsWidgetCopy } from "../components/HLatestStandingsWidget copy";
import { HTwitterFeed } from "../components/HTwitterFeed";
// import { TestCascader } from "../components/TestCascader";
import { PathContext } from "../context/PathContext";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { Spin, Button, Space } from "antd";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../context/TeamProvider";
import { HBulletinBoard } from "../components/HBulletinBoard";
import { HSchedulePic } from "../components/HSchedulePic";
import { HTeamStats } from "../components/HTeamStats";
import { CiLock, CiUnlock } from "react-icons/ci";
import { HRecruitingProfileCards } from "../components/HRecruitingProfileCards";

export default function Home() {
  const [showTeamFeed, setShowTeamFeed] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [schExpand, setSchExpand] = useState(false);
  const [teamStatsExpand, setTeamStatsExpand] = useState(true);
  const [weatherExpanded, setWeatherExpanded] = useState(false);

  const { teamSelected } = useContext(TeamContext);

  const handleWeatherLoaded = () => {
    setIframeLoaded(true);
  };

  const { selectedLocation } = useContext(PathContext);
  const mapLat = selectedLocation?.lat
    ? parseFloat(selectedLocation?.lat.toFixed(3))
    : 40.712;
  const mapLng = selectedLocation?.lng
    ? parseFloat(selectedLocation?.lng.toFixed(3))
    : 74.006;

  const scrollableDivRef = HeaderScrollVisibility();

  return (
    <motion.div
      className="h-screen w-full overflow-auto scrollbar-hide"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
	  transition={{duration: .75, type: 'spring' }}
      exit={{ y: 500, opacity: 0 }}
      ref={scrollableDivRef}
    >
      <div className="h-fit w-full mx-auto lg:mr-4 lg:ml-auto md:w-[85%] lg:w-[98%]">
        <LogoPageHeader title={"Daily Operations"} />

        <div className="grid grid-cols-6 gap-2 grid-flow-row mb-12 shadow-md shadow-b-black relative py-4 pt-2 px-2 text-center bg-slate-100 bg-opacity-20  mx-auto my-2 mt-0 h-fit rounded-lg rounded-t-none sm:rounded-t-lg">
          <motion.div className="absolute z-30 bottom-full left-3">
            <div className="h-fit w-fit p-4 px-6 pb-1 bg-slate-100 bg-opacity-20  rounded-t-md">
              <img
                className="h-24 min-w-24 rounded-sm"
                src={`${teamSelected?.img}`}
              />
            </div>
          </motion.div>

          <div className="col-span-4 w-full">
            <Space direction="vertical" size="small" className="w-full">
              <HBulletinBoard />
              <div className="grid grid-cols-2 w-full">
                <div className="col-span-1 mr-3">
                  <div
                    className={`relative z-30 min-h-9 transition-all bg-slate-100 bg-opacity-90 p-1 border-2 border-t-8 border-slate-900  shadow-sm shadow-slate-800 rounded-md rounded-t-lg pt-0`}
                  >
                    <h3 className="w-2/3 relative z-30 pb-1 -ml-1 bg-slate-900 shadow-sm shadow-slate-800 text-white rounded-br-lg text-center font-Russo tracking-wider">
                      Latest Standings
                    </h3>
                    <HLatestStandingsWidgetCopy />
                  </div>
                </div>
                <div className="w-full col-span-1">
                  <div
                    className={`relative z-30 min-h-9 bg-slate-100 bg-opacity-90 transition-all border-2 border-t-8 border-slate-900  shadow-sm shadow-slate-800 rounded-md rounded-t-lg pt-0`}
                  >
                    <h3 className="w-2/3 pb-1 bg-slate-900 shadow-md shadow-slate-800 md:block relative z-20 text-white rounded-br-lg -ml-[2px] text-center font-Russo tracking-wider">
                      Public Profiles
                    </h3>
                    <HRecruitingProfileCards />
                  </div>
                </div>
              </div>
            </Space>
          </div>

          <div className="flex flex-col w-full col-span-2 max-h-full">
            <div
              className={`pb-3 relative z-30 min-h-9 transition-all border-t-8 border-slate-900 rounded-md rounded-t-lg pt-0`}
            >
              <h3 className="w-2/3 pb-1 bg-slate-900 shadow-md shadow-slate-800 md:block relative z-20 text-white rounded-br-lg text-center font-Russo tracking-wider">
                Live Scores
              </h3>
              <HLatestScoresWidget setShowTeamFeed={setShowTeamFeed} />
            </div>
            
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: teamStatsExpand ? "13rem" : "0" }}
                className="min-h-9 overflow-hidden bg-slate-100 bg-opacity-90 backdrop-blur-md shadow-sm shadow-slate-800 rounded-sm rounded-b-md rounded-t-lg p-1 pt-0 border-2 border-slate-900 border-t-8"
              >
                <h3
                  onClick={() => setTeamStatsExpand(!teamStatsExpand)}
                  className="hover:text-green-400 w-1/2 px-5 pb-1 cursor-pointer bg-slate-900 shadow-md shadow-slate-600 md:block relative z-40 -mx-1 text-white rounded-br-lg text-center font-Russo tracking-wider"
                >
                  <div className="flex hover:text-green-400 mx-auto w-fit h-fit">
                    <div>Team Numbers{" "}</div>
                    <div className="text-sm pt-1 ml-2">
                      {teamStatsExpand ? <CiLock /> : <CiUnlock />}
                    </div>
                  </div>
                </h3>
                <HTeamStats />
              </motion.div>

            <motion.div
              initial={{ height: 0 }}
              animate={{ height: weatherExpanded ? "54vh" : "0" }}
              className="relative mt-1 overflow-hidden mx-auto min-h-9 w-full border-t-8 border-slate-900 rounded-lg border-2"
            >
              <h3
                onClick={() => setWeatherExpanded(!weatherExpanded)}
                className="absolute cursor-pointer top-0 left-0 w-1/2 pb-1 bg-slate-900 shadow-md shadow-slate-600 text-white rounded-br-lg text-center font-Russo tracking-wider"
              >
                <div className="flex mx-auto w-fit hover:text-green-400">
                  Weather Report{" "}
                  <div className="text-sm pt-1 ml-2">
                    {weatherExpanded ? <CiLock /> : <CiUnlock />}
                  </div>
                </div>
              </h3>
              {!iframeLoaded && (
                <div className="mx-auto w-fit bg-slate-100 bg-opacity-20 rounded-md mt-12 text-white font-bold text-xl p-3">
                  Loading Weather <Spin />
                </div>
              )}

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: iframeLoaded ? 1 : 0 }}
				transition={{ delay: 1 }}
                exit={{ opacity: 0 }}
              >
                <iframe
                  title="weather widget"
                  className="rounded-lg mx-auto mb-auto h-[53vh] w-full shadow-sm shadow-black"
                  src={`https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=in&metricTemp=°F&metricWind=mph&zoom=6&overlay=rain&product=ecmwf&level=surface&lat=${mapLat}&lon=${mapLng}&detailLat=${mapLat}&detailLon=${mapLng}&detail=true`}
                  frameBorder="0"
                  onLoad={handleWeatherLoaded}
                ></iframe>
              </motion.div>
            </motion.div>

            <div className="max-h-full mb-1">
              <motion.div
                animate={{ height: schExpand ? "100%" : 0 }}
			
                className={`col-span-2 mt-2 overflow-hidden max-h-fit relative min-h-9 transition-all border-2 border-t-8 border-slate-900 bg-slate-100 bg-opacity-90 backdrop-blur-md shadow-sm shadow-slate-800 rounded-md rounded-t-lg pt-0`}
              >
                <h3
                  onClick={() => setSchExpand(!schExpand)}
                  className="w-1/2 relative cursor-pointer z-30 pb-[2px]  bg-slate-900 shadow-sm shadow-slate-600 text-white rounded-br-lg text-center font-Russo tracking-wider"
                >
                  <div className="flex mx-auto w-fit hover:text-green-400">
                    Practice Schedule{" "}
                    <div className="text-sm pt-1 ml-2">
                      {schExpand ? <CiLock /> : <CiUnlock />}
                    </div>
                  </div>
                </h3>
                <HSchedulePic />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
