import React, { createContext, useState, useContext, useRef } from 'react';

// Step 1: Create HeaderContext
export const HeaderContext = createContext();

// Step 2: HeaderContextProvider
export const HeaderContextProvider = ({ children }) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const scrollableDivRef = useRef(null);

  return (
    <HeaderContext.Provider value={{ isHeaderVisible, setIsHeaderVisible  }}>
      <div ref={scrollableDivRef} style={{ overflowY: 'auto', height: '100vh' }}>
        {children}
      </div>
    </HeaderContext.Provider>
  );
};

// Custom hook to consume HeaderContext
export const useHeaderVisibility = () => {
  const { isHeaderVisible } = useContext(HeaderContext);
  return isHeaderVisible;
};
