import { notification } from 'antd'

export function openNotificationWithIcon (type, message, description, placement, btn, duration) {
		notification[type]({
		  message,
		  description,
		  placement,
		  btn,
		  duration,
		});
	  };
