import React, { useContext, useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../Firebase-config";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { TeamContext } from "../context/TeamProvider";
import { doc, updateDoc } from "@firebase/firestore";
import { Upload } from "antd";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import ImgCrop from "antd-img-crop";

export function HSchedulePic() {
  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);
  const { teamSelected } = useContext(TeamContext);

  const [fileList, setFileList] = useState([])

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(storage, `${selectedPath}/DailySchedules/${file.name}`);

      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess: async (response, info) => {
      console.log("File uploaded successfully:", response);

      // Define the Firestore document reference
      const teamDocRef = doc(db, "Teams", selectedPath);

      try {
        await updateDoc(teamDocRef, { schedulePic: response });
        console.log("Document successfully updated with image URL");
		// Modify fileList directly to set status as "done"
		const updatedFileList = info.fileList.map((item) => {
			if (item.uid === info.file.uid) { // Check for matching file
			  return { ...item, status: 'done' };
			}
			return item;
		  });
	  
		  // (Optional) Update the Upload component's fileList prop if needed
		  setFileList(updatedFileList);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  return (
    <div className="h-full overflow-hidden relative">
      <div className="h-fit w-full flex -mb-2">
        <div className="flex w-full">
          <div className="w-4/6 bg-slate-100 shadow-sm shadow-slate-800 px-1 scale-90 h-full cursor-pointer rounded-md mx-auto">
            <ImgCrop aspect={8.5 / 11} rotationSlider>
              <Upload className="hover:text-blue-400 transition-colors" fileList={fileList} {...uploadProps}>
                Click to upload Schedule
              </Upload>
            </ImgCrop>
          </div>
        </div>
      </div>
      <div className="relative z-20 pt-2 h-full w-full overflow-hidden">
        <div className="h-full w-full rounded-md">
          <img
            className="h-fit w-full border border-black rounded-sm"
            src={
              teamSelected.schedulePic
                ? teamSelected.schedulePic
                : "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/Practice%20Schedule%20ScreenShot.png?alt=media&token=a0b8e771-2727-4025-bf39-e8f66e5ce3a6"
            }
            alt="Schedule Screenshot"
          />
        </div>
      </div>
    </div>
  );
}
