import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import "firebase/database";

const firebaseConfig = {
  // Make sure to hide apiKey. Maybe?
    apiKey: 'AIzaSyCTbDi3G5zUuvU-L733W5rP-8yKtbfufAg',
    authDomain: "gradeapp-fddad.firebaseapp.com",
    projectId: "gradeapp-fddad",
    storageBucket: "gradeapp-fddad.appspot.com",
    messagingSenderId: "58525394739",
    appId: "1:58525394739:web:bf3b2dae5ca30417714046",
    measurementId: "G-XJZSDBGTGL",
  };

  const app = initializeApp(firebaseConfig);

  export const functions = getFunctions(app, 'us-east4');
  export const perf = getPerformance(app);
  export const db = getFirestore(app);
  export const auth = getAuth(app);
  export const storage = getStorage(app);
  // Already added to config and cloud functions. Can be hidden using cloud secrets if needed.
  // export const STRIPE_PUBLISHABLE_KEY = "pk_test_51MwP2EGqTnaqylD9ZEHsRFsuuVlZx4T3RplgCew5hPhkov4xtG4j5ADFEOHiG1TzGQisr6gQ9nqlCNRJvgiHorzs00X4U6WW4P"
