import React, { createContext, useState, useEffect, useContext } from "react";
import { PathContext } from "../context/PathContext";
import {
  query,
  collection,
  where,
  onSnapshot,
  writeBatch,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { AuthContext } from '../context/AuthContext';
import { db } from "../Firebase-config";

export const TeamContext = createContext({
  teamRosterData: [],
  teamsAccessible: [],
  teamSelected: {},
  loading: true,
  handleSaveRosterData: () => {}, // Initial empty function
});

export const TeamProvider = ({ children }) => {
  const [teamRosterData, setTeamRosterData] = useState([]);
  const [teamsAccessible, setTeamsAccessible] = useState([]);
  const [teamSelected, setTeamSelected] = useState([])

  const [loading, setLoading] = useState(true); // Add loading state

  const { selectedPath } = useContext(PathContext);
  const {currentUser} = useContext(AuthContext);
  const { updateLocation } = useContext(PathContext);

  const fetchTeamsAccessible = async () => {  
	const batch = writeBatch(db);

	const adminQuery = query(
	  collection(db, "Teams"),
	  where("adminUserID", "==", currentUser.uid)
	);
	const adminSnapshot = await getDocs(adminQuery);

	const coachesQuery = query(
	  collection(db, "Teams"),
	  where("Coach", "array-contains", currentUser.email)
	);
	const coachesSnapshot = await getDocs(coachesQuery);

	const playersQuery = query(
	  collection(db, "Teams"),
	  where("Players", "array-contains", currentUser.email)
	);
	const playersSnapshot = await getDocs(playersQuery);

	// Commit the batch after all operations have completed
	await batch.commit();

	const combinedData = [
	  ...adminSnapshot.docs,
	  ...coachesSnapshot.docs,
	  ...playersSnapshot.docs,
	].map((doc, index) => {
	  const role = index < coachesSnapshot.docs.length ? "Coach" : "Player";
	  return { id: doc.id, ...doc.data(), role: role };
	});
	setTeamsAccessible(combinedData);
	console.log("CombData:", combinedData);
  };


  useEffect(() => {
	if (!currentUser) return;
	  fetchTeamsAccessible()
  },[currentUser])

  useEffect(() => {
	if (!currentUser) return;
    const rosterCollectionRef = collection(
      db,
      "Teams",
      selectedPath,
      "PlayerProfiles"
    );
    const querySnapshot = query(
      rosterCollectionRef,
      where("activePlayer", "==", "Active")
    );
    const unsubscribe = onSnapshot(
      querySnapshot,
      (snapshot) => {
        let list = [];
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data(), isEditing: false });
        });
        setTeamRosterData(list);
        setLoading(false); // set loading to false after data has been retrieved
      },
      (error) => {
        console.log(error);
        setLoading(false); // handle error and set loading to false
      }
    );

    return () => {
      unsubscribe();
    };
  }, [selectedPath]);


useEffect(() => {
  if (!selectedPath || !teamsAccessible) return;

  // Firestore path to the selected team document
  const teamDocRef = doc(db, "Teams", selectedPath);

  // Set up the snapshot listener
  const unsubscribe = onSnapshot(teamDocRef, (doc) => {
    const teamData = doc.data();
    const activeTeamName = teamData ? { id: doc.id, ...teamData } : "No Team Selected";
    setTeamSelected(activeTeamName);

    const selectedLocationPath = teamData?.latlngAddress;
    updateLocation(selectedLocationPath);
  });

  // Clean up the listener when the component unmounts or selectedPath changes
  return unsubscribe;
}, [selectedPath, teamsAccessible]);


  const handleSaveRosterData = async (id, newData, field) => {
    try {
        const batch = writeBatch(db);
        const profileDocRef = doc(
            db,
            "Teams",
            selectedPath,
            "PlayerProfiles",
            id
        );

        // Update profile data
        let updatedData = {};
        for (const field in newData) {
            if (
                field === "helmet" ||
                field === "shoulderPads" ||
                field === "pants" ||
                field === "scoutTeam" ||
                field === "redshirt" ||
                field === "medicalRedshirt"
            ) {
                updatedData[field] = newData[field] === "Y" ? "N" : "Y";
            } else if (field === "publicRecruit") {
                updatedData[field] = newData[field] === "Yes" ? "No" : "Yes";
            } else {
                updatedData[field] = newData[field];
            }
        }
        batch.update(profileDocRef, updatedData);

        // Update access control list in Firestore
        if (field === "activePlayer" || field === "email") {
            const accessControlListRef = doc(db, "Teams", selectedPath);
            const docSnapshot = await getDoc(accessControlListRef);
            const accessControlList = docSnapshot.data().Players || [];
			console.log(accessControlList)

            // Fetch the old email from PlayerProfiles document
            const profileDocSnapshot = await getDoc(profileDocRef);
            const previousEmail = profileDocSnapshot.data().email;
			console.log(previousEmail)			

            const newEmail = newData.email;
            const isActivePlayer = profileDocSnapshot.data().activePlayer === "Active";
			console.log(isActivePlayer)
			console.log(newEmail)

            // If the email is different or activePlayer field is being updated
            if (previousEmail !== newEmail || field === "activePlayer") {
                if (isActivePlayer) {
                    const index = accessControlList.findIndex(
                        (email) => email === previousEmail
                    );
					console.log(index)
                    if (index > -1) {
                        accessControlList[index] = newEmail;
                    } else {
                        accessControlList.push(newEmail);
                    }
                } else {
                    const index = accessControlList.findIndex(
						(email) => email === newEmail || email === previousEmail
					);
                    if (index > -1) {
                        accessControlList.splice(index, 1);
                    }
                }
            }

            batch.update(accessControlListRef, { Players: accessControlList });
        }

        // Commit the batch write operation
        await batch.commit();
        console.log("Batch update completed successfully");
    } catch (error) {
        console.error("Error updating Firestore in batch: ", error);
    }
};


  return (
    <TeamContext.Provider
      value={{ teamRosterData, teamsAccessible, teamSelected, loading, handleSaveRosterData, fetchTeamsAccessible }}
    >
      {children}
    </TeamContext.Provider>
  );
};
