import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BsListOl } from "react-icons/bs";
import { AuthContext } from "../context/AuthContext";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderContext } from "../context/HeaderContext";
import { FaHeadset } from "react-icons/fa6";

export function LeftNav() {
  const [show, toggleShow] = useState(true);
  const { isHeaderVisible } = useContext(HeaderContext);
  const {currentUser} = useContext(AuthContext)


  useEffect(() => {
    console.log("ScrollerListener", isHeaderVisible);
    if (isHeaderVisible) {
      toggleShow(true);
    } else if (!isHeaderVisible) {
      toggleShow(false);
    }
  }, [isHeaderVisible]);

  return (
    <>
      {show && (
        <button onClick={() => toggleShow((show) => !show)} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="LeftNav-button w-6 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
            />
          </svg>
        </button>
      )}

      {!show && (
        <button onClick={() => toggleShow((show) => !show)} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="LeftNav-button w-6 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
            />
          </svg>
        </button>
      )}

      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            className="TopNav-Container font-Russo font-thin tracking-wider"
          >
            <LeftNavOpen />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

function LeftNavOpen() {
  const [proMember, setProMember] = useState();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getProMember = async () => {
      const role = currentUser?.reloadUserInfo?.customAttributes;
      console.log(role);
      if (role === '{"stripeRole":"Pro"}') {
        setProMember(true);
      } else {
        setProMember(false);
      }
    };
    getProMember();
  }, [currentUser]);

  const SideBarIcon = ({ icon, text = "tooltip" }) => (
    <div className="TopNav-link group">
      {icon}

      <span className="TopNav-tooltip group-hover:scale-100">{text}</span>
    </div>
  );

  return (
    <ul className="flex mx-auto md:mx-auto gap-0 md:gap-5 lg:gap-10 w-fit overflow-x-scroll overflow-y-clip sm:overflow-x-visible sm:overflow-y-visible scrollbar-hide">
      <li className="">
        <SideBarIcon
          icon={
            <>
              <NavLink
                to="/Home"
                className={({ isActive }) => {
                  return isActive ? "text-green-800" : {};
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="m-auto w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </NavLink>
            </>
          }
          text={"Home"}
        />
      </li>
      <li>
        <SideBarIcon
          icon={
            <>
              <NavLink
                to="/RosterEditor"
                className={({ isActive }) => {
                  return isActive ? "text-green-800" : {};
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mx-auto w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                  />
                </svg>
              </NavLink>
            </>
          }
          text={"Roster Editor"}
        />
      </li>
      <li>
        <SideBarIcon
          icon={
            <>
              <NavLink
                to="/DepthCharts"
                className={({ isActive }) => {
                  return isActive ? "text-green-800" : {};
                }}
              >
                <BsListOl className="mt-1 scale-150 text-xl mx-auto" />
              </NavLink>
            </>
          }
          text={"Depth Charts"}
        />
      </li>
      {proMember && (
        <li>
          <SideBarIcon
            icon={
              <>
                <NavLink
                  to="/GradeSheet"
                  className={({ isActive }) => {
                    return isActive ? "text-green-800" : {};
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" mx-auto w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </NavLink>
              </>
            }
            text={"Grade Sheet"}
          />
        </li>
      )}
      <li>
        <SideBarIcon
          icon={
            <>
              <NavLink
                to="/SeasonSelect"
                className={({ isActive }) => {
                  return isActive ? "text-green-800" : {};
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mx-auto w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>
              </NavLink>
            </>
          }
          text={"Team Stats"}
        />
      </li>
      <li>
        <SideBarIcon
          icon={
            <>
              <NavLink
                to="/VisualData"
                className={({ isActive }) => {
                  return isActive ? "text-green-800" : {};
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mx-auto w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
              </NavLink>
            </>
          }
          text={"Player Stats"}
        />
      </li>
      {proMember && (
        <li>
          <SideBarIcon
            icon={
              <>
                <NavLink
                  to="/WristCaller"
                  className={({ isActive }) => {
                    return isActive ? "text-green-800" : {};
                  }}
                >
                  <FaHeadset className="w-6 h-6 pt-1 font-thin mx-auto" />
                </NavLink>
              </>
            }
            text={"WristCaller"}
          />
        </li>
      )}
      {proMember && (
        <li>
          <SideBarIcon
            icon={
              <>
                <NavLink
                  to="/Recruit"
                  className={({ isActive }) => {
                    return isActive ? "text-green-800" : {};
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mx-auto"
                  >
                    <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                    <path
                      fillRule="evenodd"
                      d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z"
                      clipRule="evenodd"
                    />
                    <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
                  </svg>
                </NavLink>
              </>
            }
            text={"Recruit"}
          />
        </li>
      )}
    </ul>
  );
}
