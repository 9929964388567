import React, { useEffect, useState, useContext, useRef } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { BsFacebook } from "react-icons/bs";
import { ImProfile } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import { Transition, Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../Firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { Popconfirm, Spin } from "antd";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { TeamContext } from "../context/TeamProvider";
import { ProfilePage } from "../components/ProfilePage";
import { AnimatePresence, motion } from "framer-motion";
import { db } from "../Firebase-config";
import {
  collection,
  getDocs,
  where,
  query,
  writeBatch,
} from "firebase/firestore";
import { StripeUI } from "../stripePayments/StripeUI";
import { HActiveTeam } from "../components/HActiveTeam";
import { InternetStatus } from "../helperFunctions/InternetStatus";

export function Header({ proMember }) {
  const [profilePageOpen, setProfilePageOpen] = useState(false);
  const [open, toggleOpen] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
//   const [loading, setLoading] = useState(true);

  const addClose = () => setProfilePageOpen(false);
  const addOpen = () => setProfilePageOpen(true);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const { teamsAccessible } = useContext(TeamContext);
  const { teamSelected } = useContext(TeamContext);

  const [hiddenTeams, setHiddenTeams] = useState([]);

  const RenderTeamsAccessible = () => {
    const hideAccessibleTeam = (teamId) => {
      setHiddenTeams([...hiddenTeams, teamId]);
    };

    const unhideAccessibleTeam = (teamId) => {
      setHiddenTeams(hiddenTeams.filter((id) => id !== teamId));
    };

    return (
      <div
        className="absolute flex flex-col m-2 mt-4 overflow-y-auto scrollbar-hide w-fit h-fit max-h-[80vh] shadow-md bg-slate-200 rounded-md  shadow-slate-800"
        key="teamList"
      >
        {teamsAccessible &&
          teamsAccessible.map((team, index) => {
            if (hiddenTeams.includes(team.id)) {
              return null; // Skip rendering of hidden teams
            }

            return (
              <div
                className="flex m-2 bg-slate-500 text-white rounded-md shadow-md shadow-slate-800"
                key={team.id}
              >
                {/* <div className="flex flex-col w-32 justify-center text-white min-h-[5rem] bg-slate-800 font-bold rounded-sm">
                  <img className="h-10 rounded-lg mx-auto mt-1" src={team.img} />
                  <span className="mx-auto text-center mb-1">{team.teamName}</span>
                </div> */}
                <div className="flex w-32 text-white min-h-[5rem] bg-slate-800 font-bold rounded-sm">
                  {/* <img
                      className="h-10 w-10 rounded-xl my-auto ml-1"
                      src={team.img}
                    /> */}
                  <span className="my-auto text-center mr-1 w-full">
                    {team.teamName ? team.teamName : <Spin className="pt-1 pl-1 mr-auto" />}
                  </span>
                </div>

                <HActiveTeam teamId={team.id} />
              </div>
            );
          })}
      </div>
    );
  };

  const logOut = async () => {
    setShowTeams(false);
    toggleOpen(false);
    navigate("/");
    try {
      await signOut(auth);
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.log(error);
    }
  };

  const { pathname } = useLocation();
  const textHeader = () => {
    switch (pathname) {
      case "/Home":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">Home</p>
        );
        break;
      case "/RosterEditor":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">Roster</p>
        );
        break;
      case "/DepthCharts":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">
            Depth Charts
          </p>
        );
        break;
      case "/GradeSheet":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">Grade</p>
        );
        break;
      case "/SeasonSelect":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">
            Season Select
          </p>
        );
        break;
      case "/VisualData":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">
            Analytics
          </p>
        );
        break;
		case "/WristCaller":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">
            WristCaller
          </p>
        );
        break;
      case "/Recruit":
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">Recruit</p>
        );
        break;
      default:
        return (
          <p className="w-fit mx-auto text-white text-lg font-bold">EvaluX</p>
        );
    }
  };

  const ProfileDropdown = () => {
    return (
      <Menu className="absolute flex flex-col justify-end align-bottom p-1 pt-10 shadow-sm shadow-black z-30 top-12 sm:top-10 right-0 h-fit w-56 rounded-md bg-gray-800">
        <div>
          <StripeUI memberStatus={proMember} />

          <Menu.Button
            className="flex mt-2 self-end w-fit h-fit text-center font-bold"
            onClick={() => {
              profilePageOpen ? addClose() : addOpen();
              toggleOpen((open) => !open);
            }}
          >
            Profile Page <ImProfile className="m-1" />
          </Menu.Button>
          <Menu.Button className="self-end mt-2 w-fit h-fit text-center font-bold">
            <Popconfirm
              className="flex"
              okType="danger"
              title="Confirm Log Out?"
              onConfirm={logOut}
            >
              Log Out <GoSignOut className="m-1" />
            </Popconfirm>
          </Menu.Button>
        </div>
      </Menu>
    );
  };

  const componentRef = useRef();

  // Function to handle mousedown event
  const handleMouseDown = (event) => {
    // Prevent the event from propagating to parent elements
    event.stopPropagation();
  };

  // Function to handle click outside of the component
  const handleClickOutside = () => {
    setShowTeams(false);
    toggleOpen(false);
  };

  useEffect(() => {
    // Add event listener to the document when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed z-50 shadow-md shadow-black top-0 left-0 h-12 pt-2 sm:pt-0 sm:h-9 w-screen bg-gray-800">
      <div className="banner-wrapper h-fit w-full text-white flex">
        <AnimatePresence>
          {profilePageOpen && (
            <ProfilePage
              profilePageOpen={profilePageOpen}
              handleClose={addClose}
            />
          )}
        </AnimatePresence>
        <div className="ml-[10%] w-fit">
          <motion.button
            className="hover:bg-slate-600 px-2 pt-2 pb-1 rounded-md md:w-fit md:min-w-44 w-32 truncate font-Russo tracking-wider"
            onClick={() => setShowTeams((showTeams) => !showTeams)}
          >
            {teamSelected.teamName ? teamSelected.teamName : <Spin tip='Loading...' className="pt-1 pl-1 mx-auto" /> }
          </motion.button>
          <Transition
            className="relative ml-[8%]"
            show={showTeams}
            enter="transition ease-out duration-400"
            enterFrom="transform origin-top opacity-0 scale-95"
            enterTo="transform origin-top opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform origin-top opacity-100 scale-100"
            leaveTo="transform origin-top opacity-0 scale-95"
          >
            <div ref={componentRef} onMouseDown={handleMouseDown}>
              <RenderTeamsAccessible />
            </div>
          </Transition>
        </div>
        <div className="hidden md:flex pt-1 fixed w-screen left-0 top-0 h-0 overflow-y-visible font-thin font-Russo tracking-wider">
          {textHeader()}
        </div>

        <div className="flex relative h-full ml-auto max-w-[40%] w-fit">
          {/* <InternetStatus className='absolute top-0 right-0' /> */}
          <button
            className="text-center font-Russo tracking-wider text-yellow-300 inline-block h-full truncate hover:bg-slate-700 rounded-md pb-2 pt-1 px-2 z-50"
            type="button"
            onClick={() => toggleOpen((open) => !open)}
          >
            <span className=" text-white inline-block pr-1 scale-110 align-middle">
              <CgProfile />
            </span>
            <span className="mt-1">{currentUser?.email}</span>
          </button>

          {open}
          <Transition
            show={open}
            className="relative right-[8%]"
            enter="transition ease-out duration-100"
            enterFrom="transform origin-top opacity-0 scale-95"
            enterTo="transform origin-top opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform origin-top opacity-100 scale-100"
            leaveTo="transform origin-top opacity-0 scale-95"
          >
            <div ref={componentRef} onMouseDown={handleMouseDown}>
              <ProfileDropdown />
            </div>
          </Transition>

          <div className="hover:text-blue-600 p-2 hidden sm:flex">
            <a href="#cam">
              <BsFacebook className="scale-[90%]" />
            </a>
          </div>

          <a
            href="https://twitter.com/EvaluXApp"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-cyan-300 p-2 hidden sm:flex"
          >
            <AiFillTwitterCircle className="scale-[110%]" />
          </a>

          <a
		  target="_top"
            href="mailto:cam.dangelo@evalu-x.com"
            className="hover:text-yellow-400 p-2 hidden sm:flex"
          >
            <MdOutlineEmail />
          </a>
        </div>
      </div>
    </div>
  );
}
