import React, { useContext, useEffect, useState } from "react";
import { Button, Popover, Steps, TimePicker } from "antd";
import { PathContext } from "../context/PathContext";
import { TeamContext } from "../context/TeamProvider";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { doc, updateDoc } from "@firebase/firestore";
import { db } from "../Firebase-config";

export function HBulletinBoard() {
  const { selectedPath } = useContext(PathContext);
  const { teamSelected } = useContext(TeamContext);

  const [titleInput, setTitleInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [open, setOpen] = useState(false);
  const [bulletinItems, setBulletinItems] = useState([]);
  const [impInfo, setImpInfo] = useState();
  const [publishedTime, setPublishedTime] = useState();
  const [tempInput, setTempInput] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBulletinItems(teamSelected?.bulletin);
    setPublishedTime(teamSelected?.bulletinUpdate);
    setImpInfo(teamSelected?.impItems);
  }, [teamSelected]);

  const handleOpenEditNewItem = () => {
    setOpen(!open);
  };

  const addBulletinItem = () => {
    const newItem = {
      title: titleInput,
      description: descriptionInput,
    };

    if (!bulletinItems) {
      setBulletinItems([newItem]);
    } else {
      const updatedItems = sortItemsByTime([...bulletinItems, newItem]);
      setBulletinItems(updatedItems);
    }
    console.log(bulletinItems);
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setTempInput(e.target.value);
  };

  const addImportantItem = () => {
    if (!impInfo) {
      setImpInfo([tempInput]);
      setTempInput(""); // Clear the input field after adding
      return;
    }
    setImpInfo((prev) => [...prev, tempInput]);
    setTempInput(""); // Clear the input field after adding
  };

  const removeImportantItem = (index) => {
    setImpInfo((prev) => prev.filter((_, i) => i !== index));
  };

  const removeBulletinItem = (index) => {
    const updatedItems = bulletinItems.filter(
      (_, itemIndex) => itemIndex !== index
    );
    setBulletinItems(updatedItems);
  };

  const parseTime = (timeStr) => {
    if (!bulletinItems) return;
    const [start] = timeStr.split("-").map((s) => s.trim()); // Get the start time and trim any whitespace
    const timeParts = start.match(/(\d+):(\d+)(am|pm)/i);
    if (!timeParts) return null;

    let [, hour, minutes, meridiem] = timeParts;
    hour = parseInt(hour, 10);
    minutes = parseInt(minutes, 10);

    // Convert 12-hour format to 24-hour.
    if (hour < 12 && meridiem.toLowerCase() === "pm") hour += 12;
    if (hour === 12 && meridiem.toLowerCase() === "am") hour = 0;

    // Return a date object set to today with the extracted time.
    const date = new Date();
    date.setHours(hour, minutes, 0, 0);
    return date;
  };

  const sortItemsByTime = (items) => {
    if (!bulletinItems) return;
    return items.sort((a, b) => {
      const timeA = parseTime(a.description);
      const timeB = parseTime(b.description);
      return timeA - timeB;
    });
  };

  const [currentStatus, setCurrentStatus] = useState("wait");
  const [currentIndex, setCurrentIndex] = useState(-1);

  const getCurrentIndex = () => {
    if (!bulletinItems) return;
    const now = new Date();
    let earliestTime = null;
    let latestTime = null;

    for (let i = 0; i < bulletinItems.length; i++) {
      const [startTime, endTime] = bulletinItems[i].description
        .split("-")
        .map(parseTime);

      if (i === 0) earliestTime = startTime;
      if (i === bulletinItems.length - 1) latestTime = endTime;

      if (now >= startTime && now < endTime) {
        setCurrentStatus("process"); // Current time is within this item's timeframe
        console.log("process: during event");
        return i;
      } else if (
        now >= endTime &&
        (i === bulletinItems.length - 1 ||
          now < parseTime(bulletinItems[i + 1].description.split("-")[0]))
      ) {
        setCurrentStatus("finish"); // Current time is after this item but before the next item
        console.log("finish: between event");
        return i;
      }
    }

    if (earliestTime && now < earliestTime) {
      setCurrentStatus("wait"); // Current time is before the earliest item
      console.log("wait: before all events");

      return -1;
    }

    if (latestTime && now > latestTime) {
      setCurrentStatus("finish"); // Current time is after the latest item
      console.log("finish: after all events");

      return bulletinItems.length - 1;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(getCurrentIndex());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [bulletinItems]);

  useEffect(() => {
    setCurrentIndex(getCurrentIndex());
    setBBSaved(false);
  }, [bulletinItems]);

  useEffect(() => {
    setBBSaved(false);
  }, [impInfo]);

  const [bbSaved, setBBSaved] = useState(true);

  const saveBBoard = async (e) => {
    try {
      const currentTime = new Date().toLocaleString() + "";
      await updateDoc(doc(db, "Teams", selectedPath), {
        bulletin: bulletinItems,
        bulletinUpdate: currentTime,
        impItems: impInfo,
      });
      openNotificationWithIcon("success", "Bulletins Updated", "", "top", "", 2);
      setBBSaved(true);
      setPublishedTime(currentTime);
    } catch (error) {
      console.log(error);
      openNotificationWithIcon("error", "Failed To Update", error, "bottom", "", 3);
    }
  };

  return (
    <div
      className="relative bg-slate-100 bg-opacity-90 backdrop-blur-md h-fit w-full py-2 border border-t-8 border-slate-900 shadow-sm shadow-slate-950 rounded-lg p-1 pt-0"
    >
      <h3 className="hidden w-[25%] px-5 bg-slate-900 shadow-md shadow-slate-600 md:block relative z-20 -mx-1 text-white rounded-br-lg text-center font-Russo tracking-wider">
        Bulletin Board
      </h3>
      {publishedTime && (
        <div className="absolute text-slate-700 text-xs bottom-2 right-2">
          <span className="font-semibold">Updated:</span> {publishedTime}
        </div>
      )}
      <div className="pt-4 md:pt-0 mt-2 mx-5">
        <Steps
          className="min-h-52 mt-1"
          direction="vertical"
          size="small"
          current={currentIndex}
          status={currentStatus}
        >
          {bulletinItems &&
            bulletinItems.map((item, index) => (
              <Steps.Step
                key={index}
                title={
                  <div className="flex font-Kanit text-lg group">
                    {item.title}
                    <Button
                      className="h-full -mt-1 cursor-pointer text-slate-300 transition-all opacity-50 group-hover:opacity-100"
                      type="link"
                      onClick={() => removeBulletinItem(index)}
                    >
                      X
                    </Button>
                  </div>
                }
                description={item.description}
              />
            ))}
        </Steps>
      </div>
      <div className="absolute flex flex-col right-4 top-10">
        <Popover
          content={
            <>
              <div>
                <div className="p-1 bg-slate-800 rounded-md">
                  <input
                    className="pl-2 rounded-sm"
                    onChange={(e) => setTitleInput(e.target.value)}
                    placeholder="Title"
                  />
                </div>
                <TimePicker.RangePicker
                  onChange={(time, timeString) => {
                    const timeRange = timeString.join(" - ");
                    setDescriptionInput(timeRange);
                  }}
                  format="h:mma"
                />
              </div>
              <Button
                className="bg-slate-800 mt-2"
                type="primary"
                onClick={addBulletinItem}
              >
                Save
              </Button>
            </>
          }
          title="Title"
          trigger="click"
          open={open}
        ></Popover>
        <Button
          className="bg-slate-800 mb-1"
          type="primary"
          onClick={handleOpenEditNewItem}
        >
          Add Item
        </Button>
        <Button
          className="bg-slate-800 mb-1"
          type="primary"
          disabled={bbSaved ? true : false}
          onClick={saveBBoard}
        >
          Publish
        </Button>
      </div>
      <div className="md:absolute pb-6 md:pb-0 w-fit z-10 top-[20%] left-[35%]">
        <div className="ml-2 mr-auto w-fit text-red-500 border-b border-red-500 font-Russo">
          Important Info
        </div>
        <ul className="list-disc w-96 min-w-fit mt-2">
          {impInfo?.map((item, index) => (
            <li key={index} className="list-item ml-8">
              <div className="flex">
                <div className="ml-2 mr-auto">{item}</div>
                <button
                  className="opacity-30 ml-2"
                  onClick={() => removeImportantItem(index)}
                >
                  X
                </button>
              </div>
            </li>
          ))}
          <li className="opacity-10 hover:opacity-75 transition-all ml-8">
            <div className="w-20 min-w-fit border flex">
              <input
                className="w-full min-w-fit pl-2"
                type="text"
                value={tempInput}
                onChange={handleInputChange}
              />
              <button className="ml-2" onClick={addImportantItem}>
                Add
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
