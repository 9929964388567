import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { HeaderContextProvider } from "./context/HeaderContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <HeaderContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HeaderContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

reportWebVitals();

