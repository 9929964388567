import React, { useState, useEffect, useContext } from "react";
import { Button, Popconfirm } from "antd";
import { motion } from "framer-motion";
import { RiAddCircleFill } from "react-icons/ri";
import { db, storage } from "../Firebase-config";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { ImFilePicture } from "react-icons/im";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";

export const PPTeam = ({
  team,
  handleTeamOpen,
  teamOpen,
  updateTeamData,
  handleAddCoach,
  handleDeleteCoach,
  confirmChanges,
  handleDeleteTeam,
  index,
  showEditAddress,
  AddressSelect,
  TeamActive,
  setShowEditAddress,
}) => {
  const [editProfilePic, setEditProfilePic] = useState(false);
  const [ImgUrl, setImgUrl] = useState();

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const handleReplaceLogo = async () => {
    setEditProfilePic(false);
    await updateTeamData(team.id, "img", ImgUrl);
    confirmChanges(team.id, index);
    setImgUrl(null);
  };

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(storage, `${team.id}/TeamLogo/${file.name}`);
      const defaultProfilePictureUrl =
        "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/EVX%20Team%20Logo%20Placeholder-min.png?alt=media&token=a06f803d-ce72-43fa-98a2-1a6b43d44f33";

      // Check if data.img exists and is not the default picture
      if (team && team.img !== defaultProfilePictureUrl) {
        // Get a reference to the old image in Firebase Storage
        const oldImageRef = ref(storage, `${team.id}/TeamLogo/${team.img}`);

        try {
          // Delete the old image
          await deleteObject(oldImageRef);
          console.log("Old image deleted successfully");
        } catch (error) {
          console.error("Error deleting old image:", error);
        }
      }

      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL
            setImgUrl(downloadURL);
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess(response) {
      console.log("File uploaded successfully:", response);
      // Update your data state with the downloadURL from response
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  return (
    <div
      className="flex flex-col md:flex-col w-[90%] md:w-[97%] h-fit m-4 bg-slate-500 text-white rounded-md shadow-md shadow-slate-800"
      key={team.id + team.index}
    >
      <div className="flex flex-col relative md:flex-row w-full md:min-w-min text-white text-xl text-center justify-center min-h-[8rem] px-4 bg-slate-800 font-bold">
        <button
          onClick={() => handleTeamOpen(team.teamName)}
          className="absolute z-10 left-0 top-0 w-full h-full"
        ></button>
        {editProfilePic ? (
          <div className="relative z-20 ml-0 md:ml-5 w-fit max-w-52 h-24 my-auto flex flex-row flex-nowrap">
            {!ImgUrl ? (
              <div className="h-24 w-24 bg-white rounded-lg shadow-sm shadow-slate-900">
                <ImgCrop aspect={1} rotationSlider>
                  <Dragger {...uploadProps}>Add Team Logo</Dragger>
                </ImgCrop>
              </div>
            ) : (
              <img
                className="h-24 w-24 md:ml-4 mr-3 min-w-fit bg-slate-100 rounded-xl"
                src={ImgUrl}
                alt="profile"
              />
            )}
            <div className="flex mt-auto gap-1 ml-1">
              <Button
                className="w-fit h-fit mx-auto p-1 px-2 font-semibold rounded-md"
                onClick={() => {
                  handleReplaceLogo();
                }}
              >
                Confirm
              </Button>
              <Button
                danger
                className="w-fit h-fit text-center mx-auto p-1 px-2 font-semibold rounded-md"
                onClick={() => {
                  setEditProfilePic(false);
                  setImgUrl(null);
                }}
              >
                X
              </Button>
            </div>
          </div>
        ) : (
          <button
            onClick={() => setEditProfilePic(true)}
            className="relative z-20 min-w-fit h-24 w-fit m-auto mt-2 md:my-auto md:mr-0 md:ml-6"
          >
            <div className="absolute top-1/4 opacity-0 hover:opacity-75 text-gray-50 h-full w-full">
              <ImFilePicture className="h-1/2 w-1/2 m-auto " />
            </div>
            <img
              className="h-24 w-fit my-auto min-w-fit bg-slate-100 rounded-lg"
              src={team.img}
              alt="profile"
            />
          </button>
        )}
        <div className="w-full h-full m-auto">
          <span className="text-3xl font-Russo font-thin m-auto h-fit w-fit">
            {team.teamName}
          </span>
        </div>
      </div>
      {teamOpen === team.teamName && (
        <div>
          <div className="flex flex-row flex-wrap h-fit w-[80%] sm:w-full min-w-min mx-2 mr-6">
            <div className="flex flex-col w-full md:w-1/2 text-black">
              <label
                id={team.adminUserID}
                className="rounded-sm shadow-black shadow-sm z-10 text-center px-2 mt-2 w-fit bg-[#f1efe9] font-bold"
              >
                Owner ID:
              </label>
              <input
                className="ml-1 pl-2 h-8 text-black shadow-slate-600 bg-slate-100 bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full md:w-[80%] rounded-b-md min-w-fit"
                id={team.adminUserID}
                type="text"
                defaultValue={team.adminUserID}
                onChange={(e) =>
                  updateTeamData(team.id, "adminUserId", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col w-full mt-2 md:w-1/2 text-black">
              <label
                id={team.adminEmail}
                className="rounded-sm shadow-black shadow-sm z-10 text-center px-2 w-fit bg-[#f1efe9] font-bold"
              >
                Owner Email:
              </label>
              <input
                className="ml-1 pl-2 h-8 text-black shadow-slate-600 bg-slate-100 bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full md:w-[80%] rounded-b-md min-w-fit"
                id={team.adminEmail}
                type="text"
                defaultValue={team.adminEmail}
                onChange={(e) =>
                  updateTeamData(team.id, "adminEmail", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col my-2 mt-2 w-full md:w-1/2 text-black">
              <label
                id={team.id}
                className="rounded-sm shadow-black shadow-sm z-10 text-center px-2 w-fit bg-[#f1efe9] font-bold"
              >
                Team ID:
              </label>
              <div
                className="ml-1 p-1 pl-2 mr-2 h-fit w-full md:w-[80%] shadow-slate-600 shadow-sm rounded-sm bg-slate-200"
                id={team.id}
              >
                {team.id}
              </div>
            </div>
            <div className="flex flex-col mt-2 w-full md:w-1/2 text-black">
              <label
                id={team.address}
                className="rounded-sm shadow-black shadow-sm z-10 text-center px-2 w-fit bg-[#f1efe9] font-bold"
              >
                Address:
              </label>
              {/* <motion.button className="h-[90%] w-6 bg-slate-400 rounded-lg ml-1"><BiEditAlt className=" m-auto" /></motion.button> */}
              {/* </div> */}
              <div
                className="ml-1 p-1 pl-2 mr-2 h-fit max-h-20 w-[80%] overflow-y-scroll scrollbar-hide shadow-slate-600 shadow-sm rounded-sm bg-slate-200 hover:bg-slate-100"
                id={team.address}
              >
                {showEditAddress !== team.id && team.latlngAddress ? (
                  <motion.button
                    className="hover:cursor-pointer"
                    onClick={() => setShowEditAddress(team.id)}
                  >
                    {team.address}
                  </motion.button>
                ) : (
                  <AddressSelect teamId={team.id} />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col h-[90%] w-full overflow-x-scroll scrollbar-hide my-2 mt-6 ml-2 text-black">
            <div className="flex flex-col w-[90%] md:w-1/2 text-black mb-3">
              <label
                id={team + "RecruitingContact"}
                className="z-10 rounded-sm shadow-black shadow-sm text-center px-2 w-fit bg-[#f1efe9] font-bold"
              >
                Recruiting Contact:
              </label>
              <input
                className="ml-1 pl-2 h-8 text-black shadow-slate-600 bg-slate-100 bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full md:w-[80%] rounded-b-md min-w-fit"
                id={team.recruitingCoach}
                type="text"
                defaultValue={team.recruitingCoach}
                placeholder="Name"
                onChange={(e) =>
                  updateTeamData(team.id, "recruitingCoach", e.target.value)
                }
              />
              <input
                className="mt-1 ml-1 pl-2 h-8 text-black shadow-slate-600 bg-slate-100 bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full md:w-[80%] rounded-b-md min-w-fit"
                id={team.recruitingEmail}
                type="text"
                defaultValue={team.recruitingEmail}
                placeholder="Email"
                onChange={(e) =>
                  updateTeamData(team.id, "recruitingEmail", e.target.value)
                }
              />
              <input
                className="mt-1 ml-1 pl-2 h-8 text-black shadow-slate-600 bg-slate-100 bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full md:w-[80%] rounded-b-md min-w-fit"
                id={team.recruitingPhone}
                type="text"
                defaultValue={team.recruitingPhone}
                placeholder="Phone Number"
                onChange={(e) =>
                  updateTeamData(team.id, "recruitingPhone", e.target.value)
                }
              />
            </div>

            <div className="flex ml-2 md:ml-0 w-full">
              <label className="rounded-sm shadow-black shadow-sm mb-1 text-center px-2 w-fit bg-[#f1efe9] font-bold">
                Editors:
              </label>
              <motion.button
                className="p-1 pb-2 ml-1 text-sm rounded-sm text-white hover:text-slate-300"
                onClick={() => handleAddCoach(team.id)}
              >
                <RiAddCircleFill />
              </motion.button>
            </div>
            <div className="h-fit w-fit max-w-[90%] max-h-44 pr-1 flex flex-col overflow-y-auto lg:overflow-visible lg:flex-wrap">
              {team.Coach &&
                team.Coach.map((coach, index) => (
                  <div className="flex w-fit min-w-fit mr-2">
                    <div className="font-bold rounded-sm text-white p-1 mt-0 m-1 bg-slate-800">
                      {index + 1}.
                    </div>
                    <input
                      className="p-1 pl-2 w-fit min-w-fit h-fit shadow-slate-600 shadow-sm rounded-sm bg-slate-200 text-black"
                      id={team.Coach[index]}
                      type="text"
                      defaultValue={coach}
                      onChange={(e) =>
                        updateTeamData(
                          team.id,
                          "coachName",
                          e.target.value,
                          index
                        )
                      }
                    />
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="p-1 mb-1 ml-1 rounded-sm bg-slate-400 hover:bg-red-500 text-white"
                      onClick={() => handleDeleteCoach(team.id, index)}
                    >
                      X
                    </motion.button>
                  </div>
                ))}
            </div>
          </div>

          <div className="flex flex-row py-4 w-full" key={team.buttons}>
            <div className="w-1/3  ml-5">
              <TeamActive teamId={team.id} />
            </div>

            <motion.button
              className="w-1/3 p-1 mx-5 bg-slate-400 rounded-md shadow-md focus:bg-green-500"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => confirmChanges(team.id, index)}
            >
              Save Team Info
            </motion.button>
            <motion.button
              className="w-1/3 p-1 mr-5 bg-slate-400 hover:bg-red-400 rounded-md shadow-md"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Popconfirm
                okType="danger"
                title="Sure you want to delete this team and ALL team related Data?"
                onConfirm={() => handleDeleteTeam(team.id)}
              >
                Delete Team
              </Popconfirm>
            </motion.button>
          </div>
        </div>
      )}
    </div>
  );
};
