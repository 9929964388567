import React from 'react'
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";


export const LogoPageHeader = ({title}) => {
  return (
	<h1 className="flex h-fit mt-20 w-fit min-w-16 cursor-default p-2 sm:p-6 mx-auto text-3xl sm:text-5xl col-span-2 text-white bg-slate-100 bg-opacity-20 rounded-lg rounded-b-none">
          <img
            className="h-14 min-w-16 mt-1 sm:h-20 md:-mt-3 pl-2 my-auto"
            src={EvaluxClearLogo}
            alt="Evalux Logo"
          />
          <span className="pl-2 my-auto border-l-2 text-shadow-sm shadow-black border-slate-800 font-Russo">{title}</span>
	</h1>
  )
}
