export const AccountData = [
    {
        id: 'name',
        label: 'Full Name',
        type: 'text',
        placeholder: 'Coach...'
    },
    {
        id: 'position',
        label: 'Position on Staff',
        type: 'select',
        options: ['Head Coach', 'Coordinator', 'Position Coach', 'Analyst', 'Graduate Assistant', 'Student Assistant', 'Volunteer'],
    },
    {
        id: 'team-name',
        label: 'Team Name',
        type: 'text',
        placeholder: 'Buffalo Bills...'
    },
    {
        id: 'city',
        label: 'City',
        type: 'text',
        placeholder: 'Buffalo...'
    },
    {
        id: 'state',
        label: 'State',
        type: 'text',
        placeholder: 'NY...'
    },
    {
        id: 'level',
        label: 'Level of Competition',
        type: 'select',
        options: ['High School', 'NJCAA', 'NAIA', 'Division III', 'Division II', 'Division I FCS', 'Division I FBS', 'NFL'],
    },
    {
        id: 'conference',
        label: 'Conference',
        type: 'text',
        placeholder: 'AFC...'
    },
    {
        id: 'division',
        label: 'Division',
        type: 'text',
        placeholder: 'East...'
    },
]