import React from 'react'
import { motion } from 'framer-motion'

export const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
    className='backdrop fixed overflow-hidden flex align-middle justify-center top-2 left-0 z-[100] h-full w-screen bg-[#000000e1]'
    onClick={onClick}
    intitial={{ opacity: 0 }}
    animate= {{ opacity: 1 }}
    exit={{ opacity: 0}}
    >
      <div className='absolute h-fit w-fit top-0 right-2 sm:top-4 sm:right-4 md:right-8 text-2xl font-bold hover:text-red-400 text-gray-500 cursor-pointer'>X</div>
        
        {children}
    
    </motion.div>
  )
}
